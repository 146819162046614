/**
 * Module dependencies.
 */

import { FormList } from 'app/components/organisms/crud/form-types';
import { requiredRule } from 'app/core/utils/field-rules';
import { useGetDialCodes } from 'app/hooks/app-requests/use-get-dial-codes';
import { DialCode } from 'app/types/dial-code';
import { TFunction } from 'react-i18next';
import React from 'react';
import { DialCountry } from 'app/components/atoms/country/dial-country';

/**
 * Left.
 */

const left = (translate: TFunction): FormList => [
  {
    type: 'inputField',
    name: 'name',
    label: translate('common.labels.name'),
    rules: undefined
  },

  {
    type: 'inputField',
    inputType: 'textArea',
    name: 'address',
    label: translate('common.labels.address'),
    rules: undefined
  },
  {
    type: 'columns',
    name: 'leftWire1',
    columns: [
      {
        column: 6,
        children: [
          {
            type: 'inputField',
            name: 'postalCode',
            label: translate('common.labels.postalCode'),
            rules: undefined
          }
        ]
      },
      {
        column: 12,
        children: [
          {
            type: 'inputField',
            name: 'city',
            label: translate('common.labels.city'),
            rules: undefined
          }
        ]
      }
    ]
  },
  {
    type: 'columns',
    name: 'leftWrite2',
    columns: [
      {
        column: 8,
        children: [
          {
            type: 'selectField',
            name: 'countryCode',
            showSearch: true,
            allowClear: true,
            optionLabelProp: 'minimal',
            label: translate('common.labels.countryCode'),
            rules: undefined,
            options: {
              hook: useGetDialCodes as any,
              hookProps: () => {
                return [];
              },
              normalize: (items: DialCode[]) =>
                items?.map((item: DialCode) => ({
                  value: item.id,
                  searchValue: item.name,
                  minimal: <DialCountry item={item} />,
                  label: <DialCountry item={item} />
                }))
            }
          }
        ]
      },
      {
        column: 8,
        children: [
          {
            type: 'selectField',
            name: 'nationality',
            showSearch: true,
            allowClear: true,
            optionLabelProp: 'minimal',
            label: translate('common.labels.nationality'),
            rules: undefined,
            options: {
              hook: useGetDialCodes as any,
              hookProps: () => {
                return [];
              },
              normalize: (items: DialCode[]) =>
                items?.map((item: DialCode) => ({
                  value: item.id,
                  searchValue: item.name,
                  minimal: <DialCountry item={item} />,
                  label: <DialCountry item={item} />
                }))
            }
          }
        ]
      },
      {
        column: 8,
        children: [
          {
            type: 'inputField',
            name: 'language',
            disabled: true,
            label: translate('common.labels.language'),
            rules: undefined
          }
        ]
      }
    ]
  },
  {
    type: 'columns',
    name: 'letWire3',
    columns: [
      {
        column: 12,
        children: [
          {
            type: 'checkBoxField',
            name: 'confirmed',
            label: translate('common.labels.confirmed'),
            rules: undefined
          }
        ]
      },
      {
        column: 12,
        children: [
          {
            type: 'checkBoxField',
            name: 'blocked',
            label: translate('common.labels.blocked'),
            rules: undefined
          }
        ]
      }
    ]
  },
  {
    type: 'selectField',
    name: 'accessType',
    label: translate('common.labels.accessType'),
    rules: undefined,
    options: [
      {
        value: 'user',
        label: translate('common.labels.user')
      },
      {
        value: 'backOffice',
        label: translate('common.labels.backOffice')
      },
      {
        value: 'admin',
        label: translate('common.labels.admin')
      }
    ]
  },
  {
    type: 'inputField',
    name: 'phoneNumber',
    label: translate('common.labels.phoneNumber'),
    rules: undefined
  },
  {
    type: 'columns',
    name: 'leftWire4',
    columns: [
      {
        column: 12,
        children: [
          {
            type: 'datePickerField',
            name: 'birthDate',
            label: translate('common.labels.birthDate'),
            rules: undefined
          }
        ]
      },
      {
        column: 12,
        children: [
          {
            type: 'selectField',
            name: 'gender',
            label: translate('common.labels.gender'),
            rules: undefined,
            options: [
              {
                value: 'male',
                label: translate('common.labels.male')
              },
              {
                value: 'female',
                label: translate('common.labels.female')
              },
              {
                value: 'na',
                label: translate('common.labels.notAvailable')
              }
            ]
          }
        ]
      }
    ]
  }
];

/**
 * Right.
 */

const right = (translate: TFunction): FormList => [
  {
    type: 'inputField',
    name: 'id',
    disabled: true,
    label: translate('common.labels.id'),
    rules: requiredRule(translate)
  },
  {
    type: 'inputField',
    name: 'email',
    disabled: true,
    label: translate('common.labels.email'),
    rules: requiredRule(translate)
  },
  {
    type: 'columns',
    name: 'mainWire',
    columns: [
      {
        column: 12,
        children: [
          {
            type: 'checkBoxField',
            name: 'guestAccount',
            disabled: true,
            label: translate('common.labels.guestAccount'),
            rules: undefined
          }
        ]
      },
      {
        column: 12,
        children: [
          {
            type: 'checkBoxField',
            name: 'uninstall',
            disabled: true,
            label: translate('common.labels.uninstall'),
            rules: undefined
          }
        ]
      }
    ]
  },
  {
    type: 'inputField',
    name: 'createdAt',
    disabled: true,
    label: translate('common.labels.createdAt'),
    rules: requiredRule(translate)
  },
  {
    type: 'inputField',
    name: 'updatedAt',
    disabled: true,
    label: translate('common.labels.updatedAt'),
    rules: requiredRule(translate)
  }
];

/**
 * Export `formFields`.
 */

export const formFields = (translate: TFunction): FormList => [
  {
    type: 'columns',
    gutter: 40,
    name: 'mainWire',
    columns: [
      {
        column: 6,
        children: left(translate)
      },
      {
        column: 6,
        children: right(translate)
      }
    ]
  }
];
