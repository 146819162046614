// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-detail-overview-module__actions--fWFRJ {
  display: flex;
  gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./components/pages/users/user-detail-overview/user-detail-overview.module.less"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,SAAA;AAAF","sourcesContent":["\n.actions {\n  display: flex;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `user-detail-overview-module__actions--fWFRJ`
};
export default ___CSS_LOADER_EXPORT___;
