// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show-change-password-module__submitButton--MtXQy {
  margin-left: 16px !important;
}
`, "",{"version":3,"sources":["webpack://./components/pages/users/user-detail-overview/show-change-password.module.less"],"names":[],"mappings":"AAEA;EACE,4BAAA;AADF","sourcesContent":["\n\n.submitButton {\n  margin-left: 16px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submitButton": `show-change-password-module__submitButton--MtXQy`
};
export default ___CSS_LOADER_EXPORT___;
