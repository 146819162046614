/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { RequestOptions } from 'app/types/network';
import { BiometricData } from 'app/types/biometric-data';

/**
 * Get biometric data.
 */

function getBiometricData(client: AxiosInstance, userId: string, options?: RequestOptions): Promise<BiometricData[]> {
  return client
    .get(
      resolveAppUrl('biometricData', {
        interpolations: { userId },
        params: options
      })
    )
    .then(({ data }) => data?.data);
}

/**
 * Export `useGetBiometricData`.
 */

export function useGetBiometricData(userId: string | undefined, options?: RequestOptions) {
  const client = useRequestProvider();

  return useQuery(['biometricData'], {
    queryFn: async () => {
      if (!userId) {
        return null;
      }

      return await getBiometricData(client, userId, options);
    }
  });
}
