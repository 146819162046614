/**
 * Module dependencies.
 */

import { get, range } from 'lodash';
import React, { ComponentType, useState, useMemo, useEffect, Fragment } from 'react';
import styles from './table.module.less';
import classnames from 'classnames';
import { Header } from './header';
import { Expanded } from './expanded';
import { useSearchParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { Pagination, TablePagination } from './table-pagination';
import { Column, Row } from './row';

/**
 * `Props` type.
 */

type Props<T> = {
  isLoading?: boolean;
  currentLanguage: string;
  className?: string;
  pagination?: Pagination;
  columns: Column<T>[];
  dataSource: T[];
  changePageSize?: (value: number) => void;
  onClickLine?: (item: T) => void;
  footer?: JSX.Element;
  rowKey: string;
  hasClickableRow?: (item: T) => boolean;
  expandedRowRender?: ComponentType<any>;
  expandedExtras?: any;
  style?: any;
  wrapperClassName?: string;
};

/**
 * Export `Table` component.
 */

export function Table<T>(props: Props<T>): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeRow, setActiveRow] = useState<number>(
    searchParams.get('activeRow') ? parseInt(searchParams.get('activeRow') ?? '-1') : -1
  );

  const {
    isLoading,
    className,
    footer,
    pagination,
    currentLanguage,
    changePageSize,
    columns,
    dataSource,
    onClickLine,
    hasClickableRow,
    expandedExtras,
    expandedRowRender,
    rowKey,
    wrapperClassName
  } = props;

  const style = useMemo(() => {
    const gridTemplateColumns = columns.map(item => {
      return item.size ?? 'max-content';
    });

    const gridTemplateAreas = columns.map(item => {
      return item.key;
    });

    const expandCode = !expandedRowRender
      ? ''
      : range(columns.length)
          .map(() => 'expand')
          .join(' ');

    return {
      header: {
        gridTemplateColumns: gridTemplateColumns.join(' ')
      },
      row: {
        gridTemplateColumns: gridTemplateColumns.join(' '),
        gridTemplateAreas: `
          "${gridTemplateAreas.join(' ')}"
          ${!!expandCode ? `"${expandCode}"` : ''}
        `
      }
    };
  }, [columns, expandedRowRender]);

  useEffect(() => {
    setSearchParams(searchParams => {
      if (activeRow === -1) {
        searchParams.delete('activeRow');
      } else {
        searchParams.set('activeRow', String(activeRow));
      }

      return searchParams;
    });
  }, [activeRow, setSearchParams]);

  return (
    <div className={styles.tableOverflow}>
      <div className={classnames(styles.tableWrapper, wrapperClassName)}>
        <div
          className={classnames(styles.table, className, {
            [styles.loadingTable]: isLoading
          })}
          style={{
            ...style.header,
            ...(props.style ?? {})
          }}
        >
          <Header columns={columns} />

          {(dataSource ?? []).map((item, index) => {
            const ExpandedRow = expandedRowRender;
            const hasClickable = hasClickableRow ? !!onClickLine || hasClickableRow(item) : true;

            return (
              <Fragment key={`${get(item, rowKey)}-${index}`}>
                <div
                  className={classnames(styles.row, {
                    [styles.clickable]: !!expandedRowRender || !!hasClickable,
                    [styles.active]: index === activeRow && !!expandedRowRender && hasClickable
                  })}
                  onClick={() => {
                    if (onClickLine) {
                      return onClickLine(item);
                    }

                    if (!ExpandedRow || !hasClickable) {
                      return;
                    }

                    setActiveRow(activeRow === index ? -1 : index);
                  }}
                >
                  {columns.map(props => {
                    const key = get(props, 'key');

                    return <Row {...props} currentLanguage={currentLanguage} index={index} item={item} key={key} />;
                  })}
                </div>

                {!!ExpandedRow && hasClickable && (
                  <Expanded
                    expanded={activeRow === index}
                    style={{
                      gridColumn: `1 / ${columns.length + 1}`
                    }}
                  >
                    <div className={styles.expandedRowWrapper}>
                      <ExpandedRow
                        currentLanguage={currentLanguage}
                        expanded={activeRow === index}
                        extras={expandedExtras}
                        item={item}
                      />
                    </div>
                  </Expanded>
                )}
              </Fragment>
            );
          })}

          {!!pagination && (
            <TablePagination changePageSize={changePageSize} columnsLength={columns.length} pagination={pagination} />
          )}

          {footer}
        </div>

        <div
          className={classnames(styles.loadingWrapper, {
            [styles.visible]: isLoading
          })}
        >
          <ReactLoading color={'currentColor'} type={'spinningBubbles'} width={60} />
        </div>
      </div>
    </div>
  );
}
