/**
 * Module dependencies.
 */

import { Layout, Menu } from 'antd';
import React, { useEffect, useMemo } from 'react';
import styles from './dashboard.module.less';
import { SettingOutlined, LineChartOutlined } from '@ant-design/icons';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { appRoutes } from 'app/routes';
import { MenuItem } from 'app/types/navigation';
import { sortBy } from 'lodash';
import { cookieStorage } from 'app/core/utils/cookie-storage';
import { compileMenu } from 'app/core/utils/menu';
import { AiTwotoneBank } from 'react-icons/ai';
import { Divide as Hamburger } from 'hamburger-react';
import { useCollapseMenuProvider } from 'app/components/providers/collapse-menu-provider/collapse-menu-provider';
import { useContabBreakpoints } from 'app/hooks/use-contab-breakpoints';
import classnames from 'classnames';
import { FaHandHoldingMedical } from 'react-icons/fa6';
import { LiaTableSolid } from 'react-icons/lia';

/**
 * Sub components.
 */

const { Header } = Layout;

/**
 * Flat items.
 */

function flatItems(items: MenuItem[], parent?: MenuItem): MenuItem[] {
  let result: MenuItem[] = [];

  for (const item of items) {
    if (item.children) {
      result = [...result, ...flatItems(item.children, item)];
    }

    result.push({
      ...item,
      parent
    });
  }

  return result;
}

/**
 * Resolve selected keys.
 */

export function resolveSelectedKeys(pathname: string, items: MenuItem[]): MenuItem {
  let item = flatItems(items).find(item => {
    return pathname === item.pathname;
  });

  if (item) {
    return item;
  }

  item = sortBy(flatItems(items), item => item?.pathname?.length ?? 0 * -1)
    .reverse()
    .find(item => {
      return pathname.startsWith(item?.pathname ?? '');
    });

  if (item) {
    return item;
  }

  return items?.[0];
}

/**
 * Export `DashboardHeader` component.
 */

export function DashboardHeader(): JSX.Element | null {
  const [translate] = useTranslation();
  const { size } = useContabBreakpoints();
  const { collapsed, mainMenu, setMainMenu, setCollapsed } = useCollapseMenuProvider();
  const { pathname } = useLocation();
  const user = useMemo(() => cookieStorage.get('user'), []);
  const navigate = useNavigate();
  const items = useMemo(
    () =>
      compileMenu([
        {
          key: '1',
          pathname: appRoutes.dashboard.home.base,
          icon: <LineChartOutlined />,
          label: translate('menu.dashboard')
        },
        {
          key: '2',
          pathname: appRoutes.dashboard.users.base,
          icon: <AiTwotoneBank />,
          label: translate('menu.users')
        },
        {
          key: '3',
          pathname: appRoutes.dashboard.medical.base,
          icon: <FaHandHoldingMedical />,
          label: translate('menu.medications')
        },
        {
          key: '4',
          pathname: appRoutes.dashboard.tables.base,
          icon: <LiaTableSolid />,
          label: translate('menu.tables')
        },
        /*
        {
          key: '4',
          pathname: appRoutes.dashboard.crypto.base,
          icon: <RiStockLine />,
          label: translate('menu.pharmacies')
        },
        {
          key: '5',
          pathname: appRoutes.dashboard.crypto.base,
          icon: <RiStockLine />,
          label: translate('menu.tables')
        },
        {
          key: '6',
          pathname: appRoutes.dashboard.crypto.base,
          icon: <RiStockLine />,
          label: translate('menu.development')
        },
        */
        {
          key: '7',
          pathname: appRoutes.dashboard.settings.base,
          icon: <SettingOutlined />,
          label: translate('menu.settings')
        }
      ]),
    [translate]
  );

  const selectedItem = useMemo(() => resolveSelectedKeys(pathname, items), [items, pathname]);

  useEffect(() => {
    if (pathname === appRoutes.dashboard.base) {
      navigate(appRoutes.dashboard.home.base);
    }
  }, [navigate, pathname]);

  useEffect(() => {
    if (!user) {
      navigate(appRoutes.home);
    }
  }, [navigate, user]);

  if (!user) {
    return null;
  }

  return (
    <Layout className={styles.layoutHeader}>
      <Header className={styles.header}>
        <div className={styles.menuButton} onClick={event => event.stopPropagation()}>
          <Hamburger color={'rgb(61, 197, 86)'} size={20} toggle={setCollapsed as any} toggled={collapsed} />
        </div>

        <div
          className={classnames(styles.mobileMenu, {
            [styles.opened]: mainMenu
          })}
        >
          <Menu
            className={styles.headerMenu}
            items={items}
            mode={size > 600 ? 'horizontal' : 'vertical'}
            selectedKeys={[(selectedItem?.key as string) ?? '']}
            theme={'light'}
          />
        </div>

        <div className={styles.mainMenuButton} onClick={event => event.stopPropagation()}>
          <Hamburger color={'rgb(61, 197, 86)'} size={20} toggle={setMainMenu as any} toggled={mainMenu} />
        </div>
      </Header>

      <div className={styles.layoutOutlet}>
        <Outlet />
      </div>
    </Layout>
  );
}
