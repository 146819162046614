/**
 * Module dependencies.
 */

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { appRoutes } from 'app/routes';
import { identity, pickBy } from 'lodash';
import {
  CrudEditType,
  CrudListType,
  CrudRemoveType,
  CrudTemplate,
  FormList,
  ListColumns
} from '../../../organisms/crud/form-types';

import { TFunction } from 'react-i18next';
import moment from 'moment';
import { Language } from 'app/types/language';
import React from 'react';
import { omit, pick } from 'lodash';
import { Country } from 'app/components/atoms/country/country';

/**
 * Form fields.
 */

const formFields = (translate: TFunction): FormList => [
  {
    type: 'inputField',
    name: 'name',
    translatable: true,
    label: translate('common.labels.name'),
    rules: undefined
  },
  {
    type: 'inputField',
    name: 'flag',
    label: translate('common.labels.flag'),
    rules: undefined
  },
  {
    type: 'checkBoxField',
    name: 'enabled',
    disabled: (values, { item, mainLanguage }) => {
      return mainLanguage === item.code;
    },
    label: translate('common.labels.enabled'),
    rules: undefined
  }
];

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns<Language> => [
  {
    title: translate('common.table.columns.code'),
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: translate('common.table.columns.flag'),
    dataIndex: 'flag',
    key: 'flag',
    size: '100px',
    render: item => <Country item={item} noText />
  },
  {
    title: translate('common.table.columns.name'),
    dataIndex: 'name',
    supportLocale: true,
    size: '1fr',
    key: 'name'
  },
  {
    title: translate('common.table.columns.enabled'),
    dataIndex: 'enabled',
    key: 'enabled',
    render: value => (value.enabled ? '✅' : '❌')
  },
  {
    title: translate('common.table.columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: value => moment(value.createdAt).format('YYYY-MM-DD HH:mm')
  },
  {
    title: translate('common.table.columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: value => moment(value.updatedAt).format('YYYY-MM-DD HH:mm')
  }
];

/**
 * List.
 */

function list(translate: TFunction): CrudListType<Language> {
  return {
    columns: listColumns(translate),
    languages: '*',
    route: appRoutes.dashboard.settings.languages,
    addButton: translate('common.actions.add'),
    canEdit: true,
    canRemove: true,
    resolveParams: (params: any, item?: Language) => {
      return {
        code: params.code ?? item?.code
      };
    },
    key: ['languages'],
    normalizeFilters: (data: any) => {
      const { ...filters } = data.filters;
      const result: any = {
        filters: pickBy(filters, identity),
        order: []
      };

      return result;
    },
    normalizeData: (result: any) => {
      return result;
    },
    pagination: {
      pageSize: 25,
      pageSizeOptions: [10, 25, 50, 100, 500, 1000]
    },
    endpoint: apiEndpoints.languages,
    endpointById: apiEndpoints.languagesById
  };
}

/**
 * Edit.
 */

function edit(translate: TFunction): CrudEditType<Language> {
  return {
    formFields: formFields(translate),
    endpoint: apiEndpoints.languagesById,
    languages: '*',
    route: appRoutes.dashboard.settings.languagesEdit,
    submitLabel: translate('common.actions.edit'),
    normalizePayload: (values: any) => {
      return omit(values, ['id', 'createdAt', 'updatedAt']);
    },
    normalizeInitialValues: (keys: string[], payload: Language) => {
      const data: any = pick(payload, keys);

      data.createdAt = moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss');
      data.updatedAt = moment(data.updatedAt).format('YYYY-MM-DD HH:mm:ss');

      return data;
    }
  };
}

/**
 * Remove.
 */

const remove = (): CrudRemoveType<Language> => ({
  endpoint: apiEndpoints.languagesById
});

/**
 * Config.
 */

export function createLanguagesTemplate(translate: TFunction): CrudTemplate<Language> {
  return {
    list: list(translate),
    edit: edit(translate),
    remove: remove()
  };
}
