/**
 * Module dependencies.
 */

import { Modal } from 'antd';
import { Button } from 'app/components/atoms/button/button';
import { FormInputField } from 'app/components/organisms/crud/form-components/form-input-field';
import { requiredRule, validatePasswordRule } from 'app/core/utils/field-rules';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './show-change-password.module.less';
import { usePostChangePassword } from 'app/hooks/app-requests/user/use-post-change-pasword';

/**
 * `Props` type.
 */

type Props = {
  userId: string;
  isOpen: boolean;
  onClose: () => void;
};

function useDelayClose(isOpen: boolean) {
  const timerRef = useRef<any>();
  const [isRendering, setIsRendering] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      timerRef.current = setTimeout(() => {
        setIsRendering(false);
      }, 5000);

      return;
    }

    if (timerRef.current) {
      clearTimeout(timerRef.current);

      timerRef.current = null;
    }

    setIsRendering(true);
  }, [isOpen]);

  return isRendering;
}

/**
 * Export `ShowChangePassword` component.
 */

export function ShowChangePassword({ isOpen, onClose, userId }: Props): JSX.Element | null {
  const [translate] = useTranslation();
  const isRendering = useDelayClose(isOpen);
  const form = useForm({
    defaultValues: {
      password: ''
    },
    mode: 'all'
  });

  const { mutateAsync: changePassword } = usePostChangePassword();
  const {
    handleSubmit,
    watch,
    formState: { isSubmitting, isValid }
  } = form;

  const password = watch('password');

  const onSubmit = useCallback(
    async ({ password }: any) => {
      await changePassword({
        password,
        userId
      });

      onClose();
    },
    [changePassword, onClose, userId]
  );

  useEffect(() => {
    if (!isOpen) {
      form.reset();
    }
  }, [form, isOpen]);

  if (!isRendering) {
    return null;
  }

  return (
    <Modal
      footer={[
        <Button key={'cancel'} onClick={onClose}>
          {translate('common.actions.cancel')}
        </Button>,
        <Button
          className={styles.submitButton}
          disabled={isSubmitting || !isValid}
          key={'submit'}
          onClick={handleSubmit(onSubmit)}
          type={'primary'}
        >
          {translate('changePassword.actions.change')}
        </Button>
      ]}
      onCancel={onClose}
      onOk={handleSubmit(onSubmit)}
      open={isOpen}
      title={translate('changePassword.title')}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInputField
          form={form as any}
          item={{
            inputType: 'password',
            type: 'inputField',
            label: translate('changePassword.labels.password'),
            rules: requiredRule(translate),
            name: 'password'
          }}
        />

        <FormInputField
          form={form as any}
          item={{
            inputType: 'password',
            type: 'inputField',
            label: translate('changePassword.labels.repeatPassword'),
            rules: validatePasswordRule(translate, password),
            name: 'passwordRepeat'
          }}
        />
      </form>
    </Modal>
  );
}
