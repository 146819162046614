/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { CrudRemoveType, CrudTemplate } from 'app/components/organisms/crud/form-types';
import { useCrudRequestInvalidator } from './use-crud-list';
import { RequestOptions } from 'app/types/network';
import { defaultResolveParams } from 'app/core/utils/params';

/**
 * Resolve endpoint.
 */

export function resolveEndpoint<T>(
  removeTemplate: CrudRemoveType<T>,
  params: any,
  item: T,
  options?: RequestOptions
): string {
  const resolveParams = removeTemplate.resolveParams ?? defaultResolveParams;
  const newParams = resolveParams(params, item);

  return resolveAppUrl(removeTemplate.endpoint as any, { interpolations: newParams, params: options });
}

/**
 * Remove item.
 */

function removeItem<T>(
  client: AxiosInstance,
  removeTemplate: CrudRemoveType<T>,
  params: any,
  item: T,
  options?: RequestOptions
): Promise<void> {
  const url = resolveEndpoint(removeTemplate, params, item, options);

  return client.delete(url).then(({ data }) => data);
}

/**
 * Export `useCrudDelete`.
 */

export function useCrudDelete<T>(template: CrudTemplate<T>, params: any, options?: UseMutationOptions<any, any, any>) {
  const client = useRequestProvider();
  const invalidator = useCrudRequestInvalidator(template.list?.key);

  return useMutation({
    mutationFn: async (item: T) => {
      if (!template?.remove?.endpoint) {
        console.error('invalid config REMOVE', (item as any).id, template);
        throw new Error('invalid config REMOVE');
      }

      const result = await removeItem(client, template?.remove, params, item);

      await invalidator();

      return result;
    },
    ...options
  });
}
