/**
 * Module dependencies.
 */

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { appRoutes } from 'app/routes';
import { identity, pick, omit, pickBy } from 'lodash';
import {
  CrudEditType,
  CrudListType,
  CrudRemoveType,
  CrudTemplate,
  FormList,
  ListColumns
} from '../../../organisms/crud/form-types';

import { User } from 'app/types/user';
import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import moment from 'moment';
import { TFunction } from 'react-i18next';
import { UserSettings } from 'app/types/user-settings';

/**
 * Form fields.
 */

const formFields = (translate: TFunction): FormList => [
  {
    type: 'inputField',
    name: 'key',
    disabled: true,
    label: translate('common.labels.key'),
    rules: undefined
  },
  {
    type: 'inputField',
    name: 'type',
    disabled: true,
    label: translate('common.labels.type'),
    rules: undefined
  },
  {
    type: 'inputField',
    name: 'value',
    disabled: true,
    label: translate('common.labels.name'),
    rules: undefined
  }
];

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns<UserSettings> => [
  {
    title: translate('common.table.columns.key'),
    dataIndex: 'key',
    key: 'key',
    size: '1fr'
  },
  {
    title: translate('common.table.columns.value'),
    dataIndex: 'value',
    key: 'value',
    size: '1fr'
  },
  {
    title: translate('common.table.columns.type'),
    dataIndex: 'type',
    key: 'type',
    size: '150px'
  },
  {
    title: translate('common.table.columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    size: '200px',
    render: item => moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')
  },
  {
    title: translate('common.table.columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    size: '200px',
    render: item => moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: translate('common.labels.search')
  },
  {
    type: 'selectSearch',
    name: 'accessType',
    inline: true,
    label: translate('common.labels.accessType'),
    showSearch: true,
    allowClear: true,
    options: [
      {
        label: translate('common.labels.user'),
        value: 'user'
      },
      {
        label: translate('common.labels.backOffice'),
        value: 'backOffice'
      },
      {
        label: translate('common.labels.admin'),
        value: 'admin'
      }
    ]
  }
];

/**
 * List.
 */

function list(translate: TFunction): CrudListType<UserSettings> {
  return {
    columns: listColumns(translate),
    filters: listFilters(translate),
    style: { width: '1024px' },
    route: appRoutes.dashboard.users.detail.settings,
    addButton: translate('common.actions.add'),
    canEdit: true,
    canRemove: true,
    resolveParams: (params: any, item?: UserSettings) => {
      return {
        userId: params.userId,
        settingId: params.settingId ?? item?.key
      };
    },
    key: ['userSettings'],
    normalizeFilters: (data: any) => {
      const { ...filters } = data.filters;
      const result: any = {
        filters: pickBy(filters, identity),
        order: []
      };

      return result;
    },
    normalizeData: (result: any) => {
      return result;
    },
    pagination: {
      pageSize: 25,
      pageSizeOptions: [10, 25, 50, 100, 500, 1000]
    },
    endpoint: apiEndpoints.userSettings,
    endpointById: apiEndpoints.userSettingsById
  };
}

/**
 * Remove.
 */

const remove = (): CrudRemoveType<UserSettings> => ({
  resolveParams: (params: any, item?: UserSettings) => {
    return {
      userId: params.userId,
      settingId: params.settingId ?? item?.key
    };
  },
  endpoint: apiEndpoints.userSettingsById
});

/**
 * Edit.
 */

function edit(translate: TFunction): CrudEditType<UserSettings> {
  return {
    formFields: formFields(translate),
    endpoint: apiEndpoints.userSettingsById,
    route: appRoutes.dashboard.users.detail.settingsEdit,
    submitLabel: translate('common.actions.edit'),
    normalizePayload: (values: any) => {
      return omit(values, ['id', 'createdAt', 'updatedAt', 'uninstall']);
    },
    normalizeInitialValues: (keys: string[], payload: User) => {
      const data: any = pick(payload, keys);

      data.createdAt = moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss');
      data.updatedAt = moment(data.updatedAt).format('YYYY-MM-DD HH:mm:ss');

      return data;
    }
  };
}

/**
 * Config.
 */

export function createUserSettingsTemplate(translate: TFunction): CrudTemplate<UserSettings> {
  return {
    edit: edit(translate),
    list: list(translate),
    remove: remove()
  };
}
