/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { EndpointKey, EndpointValue, resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { CrudTemplate } from 'app/components/organisms/crud/form-types';
import { useCrudRequestInvalidator } from './use-crud-list';

/**
 * Edit transaction.
 */

function editCrud(
  client: AxiosInstance,
  apiEndpoint: EndpointKey | EndpointValue,
  params: any,
  payload: any
): Promise<void> {
  return client.patch(resolveAppUrl(apiEndpoint, { interpolations: params }), payload).then(data => data?.data);
}

/**
 * Export `useCrudEdit`.
 */

export function useCrudEdit<T>(template: CrudTemplate<T>, params: any) {
  const client = useRequestProvider();
  const invalidator = useCrudRequestInvalidator(template.list?.key);

  return useMutation({
    mutationFn: async (payload: any) => {
      if (!template.edit?.endpoint || !params) {
        console.error('invalid config EDIT', params, template);
        throw new Error('invalid config EDIT');
      }

      const result = await editCrud(client, template.edit.endpoint, params, payload);

      invalidator();

      return result;
    }
  });
}
