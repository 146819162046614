/**
 * Module dependencies.
 */

import { resolveAppUrl } from 'app/core/utils/url-resolver';
import React, { useMemo } from 'react';
import styles from './country.module.less';
import { useGetLanguages } from 'app/hooks/app-requests/use-get-languages';
import { Language } from 'app/types/language';
import { cookieStorage } from 'app/core/utils/cookie-storage';
import classNames from 'classnames';

/**
 * `Props` type.
 */

type Props = {
  item?: Language;
  code?: string;
  noText?: boolean;
  extraPadding?: boolean;
};

/**
 * Export `Country` component.
 */

export function Country({ item, extraPadding, noText, code }: Props): JSX.Element {
  const { data } = useGetLanguages();
  const user = cookieStorage.get('user');
  const resolvedItem = useMemo(() => {
    return item ?? data?.find(item => item.code === code);
  }, [code, data, item]);

  const img = useMemo(
    () =>
      resolveAppUrl('flags', {
        interpolations: {
          code: resolvedItem?.flag
        }
      }),
    [resolvedItem?.flag]
  );

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.extraPadding]: extraPadding
      })}
    >
      <img className={styles.image} src={img} />

      {resolvedItem?.name && !noText && (
        <span className={styles.text}>{JSON.parse(resolvedItem.name)?.[user.language]}</span>
      )}
    </div>
  );
}
