/**
 * Module dependencies.
 */

import { useCrudById } from 'app/hooks/app-requests/crud/use-crud-by-id';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { CrudForm } from './crud-form';
import { CrudEditType, CrudTemplate, FormList, FormListField, IfConditionWidget } from './form-types';
import React, { useEffect, useMemo } from 'react';
import { castArray, flattenDeep } from 'lodash';
import { useCrudEdit } from 'app/hooks/app-requests/crud/use-crud-edit';
import { CrudTemplateProvider } from './crud-template-provider';
import { defaultResolveParams } from 'app/core/utils/params';
import { resolveAppPath } from 'app/core/utils/url-resolver';
import { Tabs } from 'antd';
import { TranslatedFieldsProvider } from 'app/components/providers/translated-fields-provider/translated-fields-provider';
import { Loading } from 'app/components/atoms/loading/loading';
import { useGetLanguages } from 'app/hooks/app-requests/use-get-languages';
import { Country } from 'app/components/atoms/country/country';

/**
 * `Props` type.
 */

type Props<T> = {
  template: CrudTemplate<T>;
};

/**
 * Resolve form keys.
 */

export function resolveFormKeys(formFields: FormList): string[] {
  const result = formFields.map((item: FormListField) => {
    switch (item.type) {
      case 'if':
        return [
          ...resolveFormKeys((item as IfConditionWidget).thenIf ?? []),
          ...resolveFormKeys((item as IfConditionWidget).elseIf ?? [])
        ];
      case 'columns':
        return [...item.columns.map((column: any) => resolveFormKeys(column.children))];
      default:
        return item.name;
    }
  });

  return flattenDeep(result);
}

/**
 * Export `CrudEdit` component.
 */

export function CrudEdit<T>({ template }: Props<T>): JSX.Element {
  const params = useParams();
  const navigate = useNavigate();
  const [currentLanguage, setCurrentLanguage] = React.useState(template.edit?.languages?.[0]);
  const { mutate: edit } = useCrudEdit(template, params);
  const { redirect, submitLabel, normalizePayload, normalizeInitialValues } = template.edit as CrudEditType<any>;
  const { data: payload, isLoading } = useCrudById(template, params);
  const formFields = useMemo(() => {
    if (typeof template.edit?.formFields === 'function') {
      return template.edit?.formFields(payload);
    }

    return template.edit?.formFields ?? [];
  }, [payload, template.edit]);

  const formKeys = useMemo(() => {
    if (!template.edit?.formFields) {
      return [];
    }

    return resolveFormKeys(formFields);
  }, [formFields, template.edit?.formFields]);

  const values = useMemo(() => {
    return normalizeInitialValues?.(formKeys, payload);
  }, [formKeys, normalizeInitialValues, payload]);

  const routeList = template?.list?.route;
  const form = useForm({
    values,
    mode: 'all'
  });

  const metadata = useMemo(
    () => ({
      payload
    }),
    [payload]
  );

  useEffect(() => {
    form.reset(values);
  }, [form, values]);

  const { data: availableLanguages } = useGetLanguages();
  const languages = useMemo(() => {
    if (typeof template?.list?.languages === 'string' && template?.list?.languages === '*') {
      setCurrentLanguage(availableLanguages?.[0].code);
      return availableLanguages ?? [];
    }

    const langs = castArray(template?.list?.languages ?? []).map(item => item.toLowerCase());

    return (availableLanguages ?? []).filter(item => {
      return langs.includes(item.code.toLowerCase());
    });
  }, [availableLanguages, template?.list?.languages]);

  const onSubmit = async (values: any) => {
    edit(normalizePayload?.(values), {
      onSuccess: () => {
        const resolveParams = template?.list?.resolveParams ?? defaultResolveParams;
        const url = resolveAppPath(redirect ?? routeList ?? '', resolveParams(params, values));

        navigate(url);
      }
    });
  };

  return (
    <Loading isLoading={isLoading}>
      <CrudTemplateProvider template={template}>
        {template.edit?.languages && (
          <Tabs
            defaultActiveKey={'1'}
            items={languages.map(item => ({
              key: item.code,
              label: <Country extraPadding item={item} />
            }))}
            onChange={item => {
              console.log('ITEM', item);
              setCurrentLanguage(item);
            }}
          />
        )}

        <TranslatedFieldsProvider>
          <CrudForm
            currentLanguage={currentLanguage}
            form={form}
            formFields={formFields}
            formType={'edit'}
            languages={languages}
            mainLanguage={languages?.[0]?.code}
            metadata={metadata}
            onSubmit={onSubmit as any}
            submitLabel={submitLabel}
            template={template}
          />
        </TranslatedFieldsProvider>
      </CrudTemplateProvider>
    </Loading>
  );
}
