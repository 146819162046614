/**
 * Module dependencies.
 */

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { appRoutes } from 'app/routes';
import { identity, pick, omit, sortBy, pickBy } from 'lodash';
import {
  CrudEditType,
  CrudListType,
  CrudRemoveType,
  CrudTemplate,
  ListColumns
} from '../../../organisms/crud/form-types';

import { User } from 'app/types/user';
import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import moment from 'moment';
import { TFunction } from 'react-i18next';
import { resolveAppPath } from 'app/core/utils/url-resolver';
import { formFields } from './form-fields';

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns<User> => [
  {
    title: translate('common.table.columns.name'),
    dataIndex: 'name',
    key: 'name',
    size: '1fr'
  },
  {
    title: translate('common.table.columns.email'),
    dataIndex: 'email',
    key: 'email',
    size: '1fr'
  },
  {
    title: translate('common.table.columns.phoneNumber'),
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    size: '150px'
  },
  {
    title: translate('common.table.columns.accessType'),
    dataIndex: 'accessType',
    key: 'accessType',
    size: '150px'
  },
  {
    title: translate('common.table.columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    size: '200px',
    render: item => moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')
  },
  {
    title: translate('common.table.columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    size: '200px',
    render: item => moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: translate('common.labels.search')
  },
  {
    type: 'selectSearch',
    name: 'accessType',
    inline: true,
    label: translate('common.labels.accessType'),
    showSearch: true,
    allowClear: true,
    options: [
      {
        label: translate('common.labels.user'),
        value: 'user'
      },
      {
        label: translate('common.labels.backOffice'),
        value: 'backOffice'
      },
      {
        label: translate('common.labels.admin'),
        value: 'admin'
      }
    ]
  },
  {
    type: 'selectSearch',
    name: 'guestAccount',
    inline: true,
    label: translate('common.labels.guestAccount'),
    showSearch: true,
    allowClear: true,
    options: [
      {
        label: translate('common.boolean.true'),
        value: 'true'
      },
      {
        label: translate('common.boolean.false'),
        value: 'false'
      }
    ]
  }
];

/**
 * List.
 */

const list = (translate: TFunction): CrudListType<User> => ({
  columns: listColumns(translate),
  filters: listFilters(translate),
  resolveParams: (params: any, item?: User) => {
    return {
      userId: params?.userId ?? item?.id
    };
  },
  expandedDataKey: item => sortBy(item.transactions, 'date').reverse(),
  route: appRoutes.dashboard.users.users,
  clickLine: (item, { navigate }) => {
    const route = resolveAppPath(appRoutes.dashboard.users.detail.base, { userId: item.id });
    navigate(route);
  },
  addButton: translate('common.actions.add'),
  canEdit: false,
  canRemove: false,
  key: ['users'],
  normalizeFilters: (data: any) => {
    const { ...filters } = data.filters;
    const result: any = {
      filters: pickBy(filters, identity),
      order: []
    };

    result.order.push({ key: 'name', direction: 'desc' });

    return result;
  },
  normalizeData: (result: any) => {
    return result;
  },
  pagination: {
    pageSize: 25,
    pageSizeOptions: [10, 25, 50, 100, 500, 1000]
  },
  endpoint: apiEndpoints.users,
  endpointById: apiEndpoints.usersById
});

/**
 * Remove.
 */

const remove = (): CrudRemoveType<User> => ({
  endpoint: apiEndpoints.usersDelete
});

/**
 * Edit.
 */

const edit = (translate: TFunction): CrudEditType<User> => ({
  formFields: formFields(translate),
  endpoint: apiEndpoints.usersById,
  redirect: appRoutes.dashboard.users.detail.base,
  resolveParams: (params: any) => params,
  route: appRoutes.dashboard.users.detail.usersEdit,
  submitLabel: translate('common.actions.edit'),
  normalizePayload: (values: any) => {
    return omit(values, ['id', 'createdAt', 'updatedAt', 'uninstall']);
  },
  normalizeInitialValues: (keys: string[], payload: User) => {
    const data: any = pick(payload, keys);

    data.createdAt = moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss');
    data.updatedAt = moment(data.updatedAt).format('YYYY-MM-DD HH:mm:ss');

    return data;
  }
});

/**
 * Config.
 */

export function createUsersTemplate(translate: TFunction): CrudTemplate<User> {
  return {
    edit: edit(translate),
    list: list(translate),
    remove: remove()
  };
}
