/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';

/**
 * `Payload` type.
 */

type Payload = {
  code: string;
  language: string;
  values: string;
};

/**
 * Missing drugs.
 */

export async function aiMissingDrugs(client: AxiosInstance, payload: any): Promise<string> {
  return await client.post(resolveAppUrl('aiMissingDrug'), payload).then(({ data }) => data.data);
}

/**
 * Export `usePostMissingDrugAI`.
 */

export function usePostMissingDrugAI() {
  const client = useRequestProvider();

  return useMutation({
    mutationFn: async (payload: Payload) => {
      return await aiMissingDrugs(client, payload);
    }
  });
}
