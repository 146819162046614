/**
 * Module dependencies.
 */

import { TFunction } from 'i18next';

/**
 * Export `requiredRule`.
 */

export const requiredRule = (translate: TFunction) => ({
  required: translate('common.errors.form.required')
});

/**
 * Export `validatePasswordRule`.
 */

export const validatePasswordRule = (translate: TFunction, password: string) => ({
  required: true,
  validate: (val: string) => {
    return password === val || translate('common.errors.form.passwordNotMatch');
  }
});
