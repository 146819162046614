/**
 * Module dependencies.
 */

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChartOutlined } from '@ant-design/icons';
import { Outlet } from 'react-router-dom';
import { Dashboard } from './dashboard';
import { appRoutes } from 'app/routes';
import { compileMenu } from 'app/core/utils/menu';
import { FaSuitcaseMedical } from 'react-icons/fa6';
import { FaHandHoldingMedical } from 'react-icons/fa';

/**
 * Export `SkinMedicalInstruments` component.
 */

export function SkinMedicalInstruments(): JSX.Element {
  const [translate] = useTranslation();
  const items = useMemo(
    () =>
      compileMenu([
        {
          key: 'overview',
          pathname: appRoutes.dashboard.medical.base,
          icon: <PieChartOutlined />,
          label: translate('medicalInstruments.menu.overview')
        },
        {
          key: 'drugs',
          pathname: appRoutes.dashboard.medical.drugs,
          icon: <FaSuitcaseMedical />,
          label: translate('medicalInstruments.menu.drugs')
        },
        {
          key: 'missingDrugs',
          pathname: appRoutes.dashboard.medical.missingDrugs,
          icon: <FaHandHoldingMedical />,
          label: translate('medicalInstruments.menu.missingDrugs')
        }
      ]),
    [translate]
  );

  return (
    <Dashboard menu={items}>
      <Outlet />
    </Dashboard>
  );
}
