/**
 * Module dependencies.
 */

import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { CrudTemplate, LoadHooksWidget } from '../form-types';
import { CrudForm } from '../crud-form';
import { set } from 'lodash';
import { Language } from 'app/types/language';

/**
 * `Props` type.
 */

type Props<T> = {
  template: CrudTemplate<T>;
  languages: Language[];
  currentLanguage: string | undefined;
  mainLanguage: string | undefined;
  metadata: any;
  formType: 'add' | 'edit';
  name: string;
  item: LoadHooksWidget;
  form: UseFormReturn<FieldValues, any>;
};

/**
 * Export `LoadHooks` component.
 */

export function LoadHooks<T>(props: Props<T>): JSX.Element {
  const { form, template, languages, currentLanguage, mainLanguage, metadata, item, formType } = props;
  const { hooks, formFields } = item as LoadHooksWidget;

  for (let i = 0; i < hooks.length; i++) {
    const hook = hooks[0];
    const result = hook.hook(...hook.hookProps);

    set(form, ['cache', 'hooks', hook.hookName], result);
  }

  return (
    <CrudForm
      currentLanguage={currentLanguage}
      form={form}
      formFields={formFields}
      formType={formType}
      languages={languages}
      mainLanguage={mainLanguage}
      metadata={metadata}
      template={template}
    />
  );
}
