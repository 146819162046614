// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.convert-missing-drug-module__extraActions--FMq9G {
  padding: 16px 0;
}
`, "",{"version":3,"sources":["webpack://./components/pages/medical-instruments/missing-drugs/convert-missing-drug.module.less"],"names":[],"mappings":"AAEA;EACE,eAAA;AADF","sourcesContent":["\n\n.extraActions {\n  padding: 16px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"extraActions": `convert-missing-drug-module__extraActions--FMq9G`
};
export default ___CSS_LOADER_EXPORT___;
