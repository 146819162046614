/**
 * Module dependencies.
 */

import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { appRoutes } from 'app/routes';
import { cookieStorage } from 'app/core/utils/cookie-storage';

/**
 * Logout.
 */

export function logout(client: AxiosInstance): Promise<void> {
  return client.post(resolveAppUrl('logout')).then(({ data }) => data);
}

/**
 * Export `syncLogout`.
 */

export async function syncLogout(client: AxiosInstance, navigate: NavigateFunction, throwable = true): Promise<void> {
  let result;

  try {
    result = await logout(client);
  } catch (error) {
    if (throwable) {
      throw error;
    }
  }

  cookieStorage.remove('token', { path: '/' });
  cookieStorage.remove('user', { path: '/' });
  cookieStorage.remove('refreshToken', { path: '/' });

  navigate(appRoutes.home);

  return result;
}

/**
 * Export `useLogout`.
 */

export function useLogout() {
  const client = useRequestProvider();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async () => {
      return await syncLogout(client, navigate);
    }
  });
}
