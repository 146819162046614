/**
 * Export `appRoutes`.
 */

export const appRoutes = {
  home: '/',
  dashboard: {
    base: '/dashboard',
    home: {
      base: '/dashboard/home'
    },
    medical: {
      base: '/dashboard/medical',
      drugs: '/dashboard/medical/drugs',
      drugsEdit: '/dashboard/medical/drugs/:id/edit',
      missingDrugs: '/dashboard/medical/missing-drugs',
      missingDrugsEdit: '/dashboard/medical/missing-drugs/:id'
    },
    users: {
      detail: {
        base: '/dashboard/users/:userId/detail',
        usersEdit: '/dashboard/users/:userId/detail/edit',
        biometricData: '/dashboard/users/:userId/biometric-data',
        settings: '/dashboard/users/:userId/settings',
        settingsEdit: '/dashboard/users/:userId/settings/:settingId',
        authorizations: '/dashboard/users/:userId/authorizations',
        pushTokens: '/dashboard/users/:userId/push-tokens',
        pinNumbers: '/dashboard/users/:userId/pin-numbers',
        devices: '/dashboard/users/:userId/devices'
      },
      base: '/dashboard/users',
      users: '/dashboard/users/users'
    },
    tables: {
      base: '/dashboard/tables',
      dialCodes: '/dashboard/tables/dial-codes',
      dialCodesEdit: '/dashboard/tables/dial-codes/:id',
      devices: '/dashboard/tables/devices'
    },
    settings: {
      base: '/dashboard/settings',
      general: '/dashboard/settings',
      profile: '/dashboard/settings/profile',
      languages: '/dashboard/settings/languages',
      languagesEdit: '/dashboard/settings/languages/:code'
    }
  }
};
