/**
 * Module dependencies.
 */

import React, { useCallback, useMemo } from 'react';
import { createDrugsTemplate } from '../drugs/form-config';
import { useTranslation } from 'react-i18next';
import { CrudAdd } from 'app/components/organisms/crud/crud-add';
import { useParams } from 'react-router-dom';
import { useGetMissingDrug } from 'app/hooks/app-requests/missing-drugs/use-get-missing-drug';
import { Loading } from 'app/components/atoms/loading/loading';
import { Button } from 'app/components/atoms/button/button';
import styles from './convert-missing-drug.module.less';
import { usePostMissingDrugAI } from 'app/hooks/app-requests/missing-drugs/use-post-missing-drug-ai';
import { FieldValues, UseFormReturn } from 'react-hook-form';

/**
 * Export `ConvertMissingDrug` component.
 */

export function ConvertMissingDrug(): JSX.Element {
  const [translate] = useTranslation();
  const { id } = useParams();
  const { data, isLoading } = useGetMissingDrug(id);
  const template = useMemo(() => createDrugsTemplate(translate), [translate]);
  const { mutateAsync: missingDrugsAI } = usePostMissingDrugAI();
  const handlePress = useCallback(
    async (form: UseFormReturn<FieldValues, any>, mainLanguage: string) => {
      const values = form.getValues();

      const result = await missingDrugsAI({
        code: data?.code ?? '',
        language: mainLanguage,
        values: JSON.stringify(values)
      });
      const response = JSON.parse(result);

      console.log('RESULT!!', response);
      form.reset(response);
    },
    [data?.code, missingDrugsAI]
  );

  const payload = useMemo(() => {
    if (!data?.user?.language) {
      return {};
    }

    return {
      registry: data?.code,
      name: JSON.stringify({
        [data?.user?.language]: data?.name
      })
    };
  }, [data?.code, data?.name, data?.user?.language]);

  return (
    <Loading isLoading={isLoading}>
      <CrudAdd
        data={payload}
        template={template}
        topChildren={({ form, mainLanguage }) => (
          <div className={styles.extraActions}>
            <Button
              onClick={() => {
                handlePress(form, mainLanguage);
              }}
            >
              {translate('common.fillOpenAI')}
            </Button>
          </div>
        )}
      />
    </Loading>
  );
}
