/**
 * Module dependencies.
 */

import { resolveAppUrl } from 'app/core/utils/url-resolver';
import React, { useMemo } from 'react';
import styles from './country.module.less';
import { DialCode } from 'app/types/dial-code';

/**
 * `Props` type.
 */

type Props = {
  item?: DialCode;
  noText?: boolean;
};

/**
 * Export `DialCountry` component.
 */

export function DialCountry({ item, noText }: Props): JSX.Element {
  const img = useMemo(
    () =>
      resolveAppUrl('flags', {
        interpolations: {
          code: item?.code
        }
      }),
    [item?.code]
  );

  return (
    <div className={styles.wrapper}>
      <img className={styles.image} src={img} />

      {!noText && <span className={styles.text}>{item?.name}</span>}
    </div>
  );
}
