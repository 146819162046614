/**
 * Module dependencies.
 */

import React, { forwardRef } from 'react';
import { TimePicker as AntdTimePicker, TimePickerProps as AntdTimePickerProps } from 'antd';
import { FormField } from '../../form-field/form-field';
import { ErrorField } from '../../error-field/error-field';
import styles from './date-picker.module.less';

/**
 * `TimePickerProps` type.
 */

export type TimePickerProps = Omit<AntdTimePickerProps, 'error'> & {
  error: any;
  className?: string;
  invalid?: boolean;
  isDirty?: boolean;
  label: string;
  isTouched?: boolean;
};

/**
 * Export `TimePickerComponent` component.
 */

export function TimePickerComponent(props: TimePickerProps, forwardedRef: any): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { className, invalid, isTouched, isDirty, label, ...rest } = props;
  const { error } = rest;

  return (
    <FormField className={className} label={label}>
      <AntdTimePicker className={styles.wrapper} ref={forwardedRef} {...rest} />

      <ErrorField error={error} isVisible={invalid || isTouched} />
    </FormField>
  );
}

/**
 * Export `TimePicker` component.
 */

export const TimePicker = forwardRef<any, TimePickerProps>(TimePickerComponent);
