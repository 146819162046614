/**
 * Module dependencies.
 */

import React from 'react';
import { Card } from '@tremor/react';
import { Title } from 'app/components/atoms/title/title';
import { useTranslation } from 'react-i18next';

/**
 * Export `TablesOverview` component.
 */

export function TablesOverview(): JSX.Element {
  const [translate] = useTranslation();

  return (
    <Card>
      <Title>{translate('tables.overview.title')}</Title>
    </Card>
  );
}
