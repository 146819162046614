/**
 * Module dependencies.
 */

import React from 'react';
import styles from './user-type.module.less';
import { UserAuthorizations } from 'app/types/user-authorizations';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
/**
 * `Props` type.
 */

type Props = {
  item: UserAuthorizations;
};

/**
 * Export `UserType` component.
 */

export function UserType({ item }: Props): JSX.Element {
  const params = useParams();
  const [translate] = useTranslation();

  const type = params.userId === item.userFrom ? 'shared' : 'received';

  return <div className={styles.wrapper}>{translate(`authorizations.types.${type}`)}</div>;
}
