/**
 * Module dependencies.
 */

import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * `GlobalStateContextType` type.
 */

type GlobalStateContextType = any;

/**
 * `GlobalStateContext` context.
 */

const GlobalStateContext = createContext<GlobalStateContextType>(null);

/**
 * Export `useGlobalStateProvider`.
 */

export function useGlobalStateProvider() {
  return useContext(GlobalStateContext);
}

/**
 * `GlobalStateProvider` container.
 */

export function GlobalStateProvider({ children }: Props): JSX.Element {
  const [storage, setStorage] = useState<any>({});

  const setGlobalStorage = useCallback((key: string, data: any) => {
    console.log('OKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK');
    setStorage((query: any) => {
      query[key] = data;

      return { ...query };
    });
  }, []);

  const getGlobalStorage = useCallback(
    (key: string) => {
      return storage[key];
    },
    [storage]
  );

  return (
    <GlobalStateContext.Provider
      value={{
        storage,
        setGlobalStorage,
        getGlobalStorage
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
}
