/**
 * Module dependencies.
 */

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { appRoutes } from 'app/routes';
import { identity, pick, omit, sortBy, pickBy } from 'lodash';
import {
  CrudAddType,
  CrudEditType,
  CrudListType,
  CrudRemoveType,
  CrudTemplate,
  ListColumns
} from '../../../organisms/crud/form-types';

import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import moment from 'moment';
import { TFunction } from 'react-i18next';
import { formFields } from './form-fields';
import { Drug } from 'app/types/drug';
import { normalizeTemplate } from 'app/components/organisms/crud/utils';

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns<Drug> => [
  {
    title: translate('common.table.columns.registry'),
    dataIndex: 'registry',
    key: 'registry',
    size: '100px'
  },
  {
    title: translate('common.table.columns.name'),
    dataIndex: 'name',
    supportLocale: true,
    key: 'name',
    size: '1fr',
    render: item => item.name
  },
  {
    title: translate('common.table.columns.therapeuticClassificationCode'),
    dataIndex: 'therapeuticClassificationCode',
    key: 'therapeuticClassificationCode',
    size: '150px',
    render: item => item.therapeuticClassificationCode ?? 'N/A'
  },
  {
    title: translate('common.table.columns.cci'),
    dataIndex: 'cci',
    key: 'cci',
    size: '150px'
  },
  {
    title: translate('common.table.columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    size: '200px',
    render: item => moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')
  },
  {
    title: translate('common.table.columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    size: '200px',
    render: item => moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: translate('common.labels.search')
  }
];

/**
 * List.
 */

const list = (translate: TFunction): CrudListType<Drug> => ({
  columns: listColumns(translate),
  filters: listFilters(translate),
  languages: '*',
  defaultQueryString: 'filter=%7B"guestAccount"%3A"false"%7D&filterOpened=false',
  resolveParams: (params: any, item?: Drug) => {
    return {
      id: params?.id ?? item?.id
    };
  },
  expandedDataKey: item => sortBy(item.transactions, 'date').reverse(),
  route: appRoutes.dashboard.medical.drugs,
  addButton: translate('common.actions.add'),
  canEdit: true,
  canRemove: true,
  key: ['drugs'],
  normalizeFilters: (data: any) => {
    const { ...filters } = data.filters;
    const result: any = {
      filters: pickBy(filters, identity),
      order: []
    };

    result.order.push({ key: 'name', direction: 'desc' });

    return result;
  },
  normalizeData: (result: any) => {
    return result;
  },
  pagination: {
    pageSize: 25,
    pageSizeOptions: [10, 25, 50, 100, 500, 1000]
  },
  endpoint: apiEndpoints.drugs,
  endpointById: apiEndpoints.drugsById
});

/**
 * Remove.
 */

const remove = (): CrudRemoveType<Drug> => ({
  endpoint: apiEndpoints.drugsDelete
});

/**
 * Normalize payload.
 */

function normalizePayload(values: any) {
  const result = omit(values, ['id', 'createdAt', 'updatedAt']);

  result.cronic = !!result.cronic;
  result.therapeuticClassificationCode = String(result.therapeuticClassificationCode);

  return result;
}

/**
 * Add.
 */

const add = (translate: TFunction): CrudAddType<Drug> => ({
  formFields: formFields(translate, 'add'),
  languages: '*',
  acceptQueryFields: [
    {
      name: 'accountId',
      parser: value => (!!value ? parseInt(value, 10) : undefined)
    }
  ],
  normalizePayload
});

/**
 * Edit.
 */

const edit = (translate: TFunction): CrudEditType<Drug> => ({
  formFields: formFields(translate, 'edit'),
  languages: '*',
  endpoint: apiEndpoints.drugsById,
  redirect: appRoutes.dashboard.medical.drugs,
  resolveParams: (params: any) => params,
  route: appRoutes.dashboard.medical.drugsEdit,
  submitLabel: translate('common.actions.edit'),
  normalizePayload,
  normalizeInitialValues: (keys: string[], payload: Drug) => {
    const data: any = pick(payload, keys);

    data.createdAt = moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss');
    data.updatedAt = moment(data.updatedAt).format('YYYY-MM-DD HH:mm:ss');

    return data;
  }
});

/**
 * Config.
 */

export function createDrugsTemplate(translate: TFunction): CrudTemplate<Drug> {
  return normalizeTemplate(translate, {
    add: add(translate),
    edit: edit(translate),
    list: list(translate),
    remove: remove()
  });
}
