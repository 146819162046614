/**
 * Module dependencies.
 */

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { appRoutes } from 'app/routes';
import { identity, pickBy } from 'lodash';
import { CrudListType, CrudRemoveType, CrudTemplate, ListColumns } from '../../../organisms/crud/form-types';

import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import { TFunction } from 'react-i18next';
import { UserPinNumber } from 'app/types/user-pin-number';
import moment from 'moment';

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns<UserPinNumber> => [
  {
    title: translate('common.table.columns.id'),
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: translate('common.table.columns.phoneNumber'),
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    size: '200px'
  },
  {
    title: translate('common.table.columns.pin'),
    dataIndex: 'pin',
    key: 'pin',
    size: '100px'
  },
  {
    title: translate('common.table.columns.validated'),
    dataIndex: 'validated',
    key: 'validated',
    render: value => translate(`common.boolean.${value.validated}`)
  },
  {
    title: translate('common.table.columns.expirationDate'),
    dataIndex: 'expirationDate',
    key: 'expirationDate',
    render: value => moment(value.expirationDate).format('YYYY-MM-DD HH:mm')
  },
  {
    title: translate('common.table.columns.generationDate'),
    dataIndex: 'generationDate',
    key: 'generationDate',
    render: value => moment(value.generationDate).format('YYYY-MM-DD HH:mm')
  },
  {
    title: translate('common.table.columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: value => moment(value.createdAt).format('YYYY-MM-DD HH:mm')
  },
  {
    title: translate('common.table.columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: value => moment(value.updatedAt).format('YYYY-MM-DD HH:mm')
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: translate('common.labels.search')
  },
  {
    type: 'selectSearch',
    name: 'accessType',
    inline: true,
    label: translate('common.labels.accessType'),
    showSearch: true,
    allowClear: true,
    options: [
      {
        label: translate('common.labels.user'),
        value: 'user'
      },
      {
        label: translate('common.labels.backOffice'),
        value: 'backOffice'
      },
      {
        label: translate('common.labels.admin'),
        value: 'admin'
      }
    ]
  }
];

/**
 * List.
 */

function list(translate: TFunction): CrudListType<UserPinNumber> {
  return {
    columns: listColumns(translate),
    filters: listFilters(translate),
    route: appRoutes.dashboard.users.detail.pinNumbers,
    addButton: translate('common.actions.add'),
    canEdit: true,
    canRemove: true,
    resolveParams: (params: any, item?: UserPinNumber) => {
      return {
        userId: params.userId,
        id: params.id ?? item?.id
      };
    },
    key: ['userPinNumbers'],
    normalizeFilters: (data: any) => {
      const { ...filters } = data.filters;
      const result: any = {
        filters: pickBy(filters, identity),
        order: []
      };

      return result;
    },
    normalizeData: (result: any) => {
      return result;
    },
    pagination: {
      pageSize: 25,
      pageSizeOptions: [10, 25, 50, 100, 500, 1000]
    },
    endpoint: apiEndpoints.userPinNumbers,
    endpointById: apiEndpoints.userPinNumbersById
  };
}

/**
 * Remove.
 */

const remove = (): CrudRemoveType<UserPinNumber> => ({
  endpoint: apiEndpoints.userPinNumbersById
});

/**
 * Config.
 */

export function createUserPinNumbersTemplate(translate: TFunction): CrudTemplate<UserPinNumber> {
  return {
    list: list(translate),
    remove: remove()
  };
}
