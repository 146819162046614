/**
 * Module dependencies.
 */

import { Alert, Card, Slider } from 'antd';
import { Title } from 'app/components/atoms/title/title';
import { useGetBiometricData } from 'app/hooks/app-requests/biometric-data/use-get-biometric-data';
import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './biometric-data.module.less';
import { Label } from 'app/components/atoms/forms/label/label';
import { useTranslation } from 'react-i18next';
import { Loading } from 'app/components/atoms/loading/loading';
import moment from 'moment';
import { Field } from 'app/components/organisms/field/field';
import { BiometricData as BiometricDataType } from 'app/types/biometric-data';

/**
 * Export `BiometricData` component.
 */

export function BiometricData(): JSX.Element {
  const { userId } = useParams();
  const [currentItem, setCurrentItem] = useState<BiometricDataType | null>(null);
  const [translate] = useTranslation();

  const { data, isLoading } = useGetBiometricData(userId);
  const formatter = useCallback(
    (value: any) => {
      const item = data?.[value];

      return moment(item?.createdAt).format('YYYY-MM-DD HH:mm');
    },
    [data]
  );

  useEffect(() => {
    if (data?.[data.length - 1]) {
      setCurrentItem(data?.[data.length - 1]);
    }
  }, [data]);

  console.log('DATA', data);

  return (
    <div className={styles.container}>
      <Loading isLoading={isLoading}>
        <Title>{translate('users.biometricData.title')}</Title>

        {data && (
          <>
            {data.length > 0 ? (
              <>
                <Label>{translate('common.labels.dateHistory')}</Label>
                <Slider
                  defaultValue={data?.length}
                  max={data?.length - 1}
                  min={0}
                  onChange={item => {
                    if (data?.[item]) {
                      setCurrentItem(data?.[item]);
                    }
                  }}
                  tooltip={{ open: true, formatter, placement: 'bottom' }}
                />
              </>
            ) : (
              <Alert message={translate('common.messages.noData')} showIcon type={'warning'} />
            )}
          </>
        )}

        {currentItem && (
          <Card className={styles.card}>
            <Field title={translate('common.labels.weight')} value={String(currentItem.weight ?? 'N/A')} />

            <Field title={translate('common.labels.height')} value={String(currentItem.height ?? 'N/A')} />

            <Field title={translate('common.labels.imc')} value={String(currentItem.imc ?? 'N/A')} />

            <Field
              title={translate('common.labels.totalCholesterol')}
              value={String(currentItem.totalCholesterol ?? 'N/A')}
            />

            <Field
              title={translate('common.labels.minimumBloodPressure')}
              value={String(currentItem.minimumBloodPressure ?? 'N/A')}
            />

            <Field
              title={translate('common.labels.maximumBloodPressure')}
              value={String(currentItem.maximumBloodPressure ?? 'N/A')}
            />

            <Field
              title={translate('common.labels.triglycerides')}
              value={String(currentItem.triglycerides ?? 'N/A')}
            />

            <Field
              title={translate('common.labels.bloodGlucoseFast')}
              value={String(currentItem.bloodGlucoseFast ?? 'N/A')}
            />

            <Field
              title={translate('common.labels.bloodGlucosePostprandial')}
              value={String(currentItem.bloodGlucosePostprandial ?? 'N/A')}
            />

            <Field
              title={translate('common.labels.glycosylatedHemoglobin')}
              value={String(currentItem.glycosylatedHemoglobin ?? 'N/A')}
            />

            <Field title={translate('common.labels.hemoglobin')} value={String(currentItem.hemoglobin ?? 'N/A')} />

            <Field title={translate('common.labels.uricAcid')} value={String(currentItem.uricAcid ?? 'N/A')} />
          </Card>
        )}
      </Loading>
    </div>
  );
}
