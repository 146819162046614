/**
 * Module dependencies.
 */

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { appRoutes } from 'app/routes';
import { identity, pickBy } from 'lodash';
import { CrudListType, CrudTemplate, ListColumns } from '../../../organisms/crud/form-types';

import { TFunction } from 'react-i18next';
import moment from 'moment';
import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import { Device } from 'app/types/device';

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns<Device> => [
  {
    title: translate('common.table.columns.id'),
    dataIndex: 'userId',
    key: 'userId',
    render: value => {
      const str = String(value.id);

      return str.length < 8 ? value.id : '...' + str.substring(str.length - 8, str.length);
    }
  },
  {
    title: translate('common.table.columns.email'),
    dataIndex: 'user.email',
    key: 'user.email'
  },
  {
    title: translate('common.table.columns.name'),
    dataIndex: 'user.name',
    size: '1fr',
    key: 'user.name'
  },
  {
    title: translate('common.table.columns.phoneNumber'),
    dataIndex: 'user.phoneNumber',
    key: 'user.phoneNumber'
  },
  {
    title: translate('common.table.columns.deviceId'),
    dataIndex: 'deviceId',
    supportLocale: true,
    key: 'deviceId'
  },
  {
    title: translate('common.table.columns.appVersion'),
    dataIndex: 'appVersion',
    key: 'appVersion'
  },
  {
    title: translate('common.table.columns.deviceName'),
    dataIndex: 'deviceName',
    key: 'deviceName'
  },
  {
    title: translate('common.table.columns.operatingSystem'),
    dataIndex: 'operatingSystem',
    key: 'operatingSystem'
  },
  {
    title: translate('common.table.columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: value => moment(value.createdAt).format('YYYY-MM-DD HH:mm')
  },
  {
    title: translate('common.table.columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: value => moment(value.updatedAt).format('YYYY-MM-DD HH:mm')
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: translate('common.labels.search')
  }
];

/**
 * List.
 */

function list(translate: TFunction): CrudListType<Device> {
  return {
    columns: listColumns(translate),
    filters: listFilters(translate),
    route: appRoutes.dashboard.tables.devices,
    addButton: translate('common.actions.add'),
    canEdit: true,
    canRemove: true,
    resolveParams: (params: any, item?: Device) => {
      return {
        id: params.id ?? item?.id
      };
    },
    key: ['devices'],
    normalizeFilters: (data: any) => {
      const { ...filters } = data.filters;
      const result: any = {
        filters: pickBy(filters, identity),
        order: []
      };

      return result;
    },
    normalizeData: (result: any) => {
      return result;
    },
    pagination: {
      pageSize: 25,
      pageSizeOptions: [10, 25, 50, 100, 500, 1000]
    },
    endpoint: apiEndpoints.devices,
    endpointById: apiEndpoints.devicesById
  };
}

/**
 * Config.
 */

export function createDevicesTemplate(translate: TFunction): CrudTemplate<Device> {
  return {
    list: list(translate)
  };
}
