// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.country-module__wrapper--T5mPT {
  width: 100%;
  display: flex;
  align-items: center;
}
.country-module__wrapper--T5mPT.country-module__extraPadding--lP4nt {
  padding: 0 0px;
}
.country-module__wrapper--T5mPT .country-module__image--wQYOQ {
  width: 32px;
  margin-right: 16px;
}
.country-module__wrapper--T5mPT .country-module__text--jamI5 {
  margin-top: 2px;
  font-size: 13px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./components/atoms/country/country.module.less"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AADF;AAGE;EACE,cAAA;AADJ;AALA;EAUI,WAAA;EACA,kBAAA;AAFJ;AATA;EAeI,eAAA;EACA,eAAA;EACA,WAAA;AAHJ","sourcesContent":["\n\n.wrapper {\n  width: 100%;\n  display: flex;\n  align-items: center;\n\n  &.extraPadding {\n    padding: 0 0px;\n  }\n\n  .image {\n    width: 32px;\n    margin-right: 16px;\n  }\n\n  .text {\n    margin-top: 2px;\n    font-size: 13px;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `country-module__wrapper--T5mPT`,
	"extraPadding": `country-module__extraPadding--lP4nt`,
	"image": `country-module__image--wQYOQ`,
	"text": `country-module__text--jamI5`
};
export default ___CSS_LOADER_EXPORT___;
