/**
 * Module dependencies.
 */

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { appRoutes } from 'app/routes';
import { identity, pickBy } from 'lodash';
import {
  CrudEditType,
  CrudListType,
  CrudRemoveType,
  CrudTemplate,
  FormList,
  ListColumns
} from '../../../organisms/crud/form-types';

import { TFunction } from 'react-i18next';
import moment from 'moment';
import React from 'react';
import { omit, pick } from 'lodash';
import { DialCode } from 'app/types/dial-code';
import { DialCountry } from 'app/components/atoms/country/dial-country';
import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';

/**
 * Form fields.
 */

const formFields = (translate: TFunction): FormList => [
  {
    type: 'inputField',
    name: 'code',
    disabled: true,
    label: translate('common.labels.code'),
    rules: undefined
  },
  {
    type: 'inputField',
    name: 'dialCode',
    disabled: true,
    label: translate('common.labels.dialCode'),
    rules: undefined
  },
  {
    type: 'inputField',
    name: 'name',
    label: translate('common.labels.name'),
    rules: undefined
  },
  {
    type: 'inputField',
    name: 'flagPath',
    disabled: true,
    label: translate('common.labels.flagPath'),
    rules: undefined
  }
];

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns<DialCode> => [
  {
    title: translate('common.table.columns.code'),
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: translate('common.table.columns.dialCode'),
    dataIndex: 'dialCode',
    supportLocale: true,
    key: 'dialCode'
  },
  {
    title: translate('common.table.columns.flag'),
    dataIndex: 'flag',
    key: 'flag',
    size: '100px',
    render: item => <DialCountry item={item} noText />
  },
  {
    title: translate('common.table.columns.name'),
    dataIndex: 'name',
    supportLocale: true,
    size: '1fr',
    key: 'name'
  },
  {
    title: translate('common.table.columns.flagPath'),
    dataIndex: 'flagPath',
    key: 'flagPath'
  },
  {
    title: translate('common.table.columns.enabled'),
    dataIndex: 'enabled',
    key: 'enabled',
    style: { display: 'flex', justifyContent: 'center' },
    render: value => (value.enabled ? '✅' : '❌')
  },
  {
    title: translate('common.table.columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: value => moment(value.createdAt).format('YYYY-MM-DD HH:mm')
  },
  {
    title: translate('common.table.columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: value => moment(value.updatedAt).format('YYYY-MM-DD HH:mm')
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: translate('common.labels.search')
  }
];

/**
 * List.
 */

function list(translate: TFunction): CrudListType<DialCode> {
  return {
    columns: listColumns(translate),
    filters: listFilters(translate),
    languages: '*',
    route: appRoutes.dashboard.tables.dialCodes,
    addButton: translate('common.actions.add'),
    canEdit: true,
    canRemove: true,
    resolveParams: (params: any, item?: DialCode) => {
      return {
        id: params.id ?? item?.id
      };
    },
    key: ['dialCodes'],
    normalizeFilters: (data: any) => {
      const { ...filters } = data.filters;
      const result: any = {
        filters: pickBy(filters, identity),
        order: []
      };

      return result;
    },
    normalizeData: (result: any) => {
      return result;
    },
    pagination: {
      pageSize: 25,
      pageSizeOptions: [10, 25, 50, 100, 500, 1000]
    },
    endpoint: apiEndpoints.dialCodes,
    endpointById: apiEndpoints.dialCodesById
  };
}

/**
 * Edit.
 */

function edit(translate: TFunction): CrudEditType<DialCode> {
  return {
    formFields: formFields(translate),
    endpoint: apiEndpoints.dialCodesById,
    languages: '*',
    route: appRoutes.dashboard.tables.dialCodesEdit,
    submitLabel: translate('common.actions.edit'),
    normalizePayload: (values: any) => {
      return omit(values, ['id', 'createdAt', 'updatedAt']);
    },
    normalizeInitialValues: (keys: string[], payload: DialCode) => {
      const data: any = pick(payload, keys);

      data.createdAt = moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss');
      data.updatedAt = moment(data.updatedAt).format('YYYY-MM-DD HH:mm:ss');

      return data;
    }
  };
}

/**
 * Remove.
 */

const remove = (): CrudRemoveType<DialCode> => ({
  endpoint: apiEndpoints.dialCodesById
});

/**
 * Config.
 */

export function createDialCodesTemplate(translate: TFunction): CrudTemplate<DialCode> {
  return {
    list: list(translate),
    edit: edit(translate),
    remove: remove()
  };
}
