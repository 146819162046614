/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Language } from 'app/types/language';

/**
 * `Options` type.
 */

type Options = {
  enabled?: boolean;
};

/**
 * Get languages.
 */

export function getLanguages(client: AxiosInstance): Promise<Language[]> {
  return client.get(resolveAppUrl('languages'), { withCredentials: true }).then(({ data }) => data?.data);
}

/**
 * Export `useGetLanguages`.
 */

export function useGetLanguages(options?: Options) {
  const client = useRequestProvider();

  return useQuery(['sys-languages'], {
    queryFn: async () => {
      return await getLanguages(client);
    },
    enabled: options?.enabled
  });
}
