/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { RequestOptions } from 'app/types/network';
import { MissingDrug } from 'app/types/drug';

/**
 * Get missing drug.
 */

function getMissingDrug(client: AxiosInstance, id: string, options?: RequestOptions): Promise<MissingDrug> {
  return client
    .get(
      resolveAppUrl('missingDrugsById', {
        interpolations: { id },
        params: options
      })
    )
    .then(({ data }) => data?.data);
}

/**
 * Export `useGetMissingDrug`.
 */

export function useGetMissingDrug(id: string | undefined, options?: RequestOptions) {
  const client = useRequestProvider();

  return useQuery(['missingDrug', id], {
    queryFn: async () => {
      if (!id) {
        return null;
      }

      return await getMissingDrug(client, id, options);
    }
  });
}
