/**
 * Module dependencies.
 */

import { QueryOptions } from 'app/types/query-options';
import { apiEndpoints } from 'app/core/config/api-endpoints';
import { isEmpty } from 'lodash';
import { template } from './template';
import config from 'config';
import qs from 'qs';
import urlJoin from 'url-join';
import { generatePath } from 'react-router-dom';
import { RequestOptions } from 'app/types/network';

/**
 * `EndpointRequestOptions` type.
 */

export type EndpointRequestOptions = {
  interpolations?: Record<string, any>;
  params?: QueryOptions | RequestOptions | Record<string, any>;
};

/**
 * Export `EndpointKey` type.
 */

export type EndpointKey = keyof typeof apiEndpoints;

/**
 * Export `EndpointValue` type.
 */

// eslint-disable-next-line prettier/prettier
export type EndpointValue = (typeof apiEndpoints)[EndpointKey];

/**
 * Get endpoint.
 */

function getEndpoint(name: EndpointKey | EndpointValue, params?: any): string {
  const endpoint = (apiEndpoints as any)[name] ?? name;

  if (!params) {
    return endpoint;
  }

  return template(endpoint, params);
}

/**
 * Resolve url.
 */

function resolveUrl(baseUrl: string, url: string): string {
  if (!baseUrl || /^https?:\/\//.test(url)) {
    return url;
  }

  return urlJoin(baseUrl, url);
}

/**
 * Export `resolveAppPath`.
 */

export function resolveAppPath(url: string, params: any) {
  return generatePath(url, params);
}

/**
 * Resolve url.
 */

export function handleQueryParams(url: string, params: Record<string, any>): string {
  if (!params || isEmpty(params)) {
    return url;
  }

  return urlJoin(url, `?${qs.stringify(params)}`);
}

/**
 * Export `resolveAppUrl` util.
 */

export function resolveAppUrl(routeKey: EndpointKey | EndpointValue, options?: EndpointRequestOptions): string {
  const { interpolations, params } = options ?? {};
  let endpointUrl = getEndpoint(routeKey, interpolations);

  if (params) {
    endpointUrl = handleQueryParams(endpointUrl, params);
  }

  return resolveUrl(config?.api?.baseUrl, endpointUrl);
}

/**
 * Export `resolveBaseEndpointUrl` util.
 */

export function resolveBaseEndpointUrl(url: string): string {
  return resolveUrl(config?.api?.baseUrl, url);
}
