/**
 * Export `defaultResolveParams`.
 */

export function defaultResolveParams<T>(params: any, item?: T) {
  return {
    ...(params ?? {}),
    id: (item as any)?.id
  };
}
