/**
 * Module dependencies.
 */

import React, { ReactNode, createContext, useCallback, useContext, useRef } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * `QueryParamsStorageContextType` type.
 */

type QueryParamsStorageContextType = any;

/**
 * `QueryParamsStorageContext` context.
 */

const QueryParamsStorageContext = createContext<QueryParamsStorageContextType>(null);

/**
 * Export `useQueryParamsStorageProvider`.
 */

export function useQueryParamsStorageProvider() {
  return useContext(QueryParamsStorageContext);
}

/**
 * `QueryParamsStorageProvider` container.
 */

export function QueryParamsStorageProvider({ children }: Props): JSX.Element {
  const storage = useRef<any>({});

  const setPageParams = useCallback((path: string, data: any) => {
    storage.current[path] = data;
  }, []);

  const getPageParams = useCallback((path: string) => {
    return storage.current[path];
  }, []);

  return (
    <QueryParamsStorageContext.Provider
      value={{
        setPageParams,
        getPageParams
      }}
    >
      {children}
    </QueryParamsStorageContext.Provider>
  );
}
