/**
 * Module dependencies.
 */

import { User } from 'app/types/user';
import React from 'react';
import styles from './user-label.module.less';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

/**
 * `Props` type.
 */

type Props = {
  user: User | undefined;
};

/**
 * Export `UserLabel` component.
 */

export function UserLabel({ user }: Props): JSX.Element {
  const params = useParams();
  const isOwner = params.userId === user?.id;

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.owner]: isOwner
      })}
    >
      <div className={styles.lead}>{user?.name ?? user?.email}</div>

      <div className={styles.note}>{user?.phoneNumber}</div>
    </div>
  );
}
