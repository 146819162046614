// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submit-button-module__wrapper--PNksu {
  margin-top: 32px;
}
`, "",{"version":3,"sources":["webpack://./components/atoms/forms/submit-button/submit-button.module.less"],"names":[],"mappings":"AACA;EACE,gBAAA;AAAF","sourcesContent":["\n.wrapper {\n  margin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `submit-button-module__wrapper--PNksu`
};
export default ___CSS_LOADER_EXPORT___;
