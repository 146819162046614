/**
 * Export `apiEndpoints`.
 */

export const apiEndpoints = {
  aiMissingDrug: '/admin/ai/missing-drug',
  login: '/auth',
  userInfo: '/auth/me',
  users: '/admin/users',
  usersById: '/admin/users/${userId}',
  usersDelete: '/admin/users/${userId}',
  usersEdit: '/admin/users/${userId}',
  dialCodes: '/admin/dial-codes',
  dialCodesById: '/admin/dial-codes/${id}',
  flags: '/mobile/dial-codes/flags/${code}',
  biometricData: '/admin/users/${userId}/biometric-data',
  userSettings: '/admin/users/${userId}/settings',
  userSettingsById: '/admin/users/${userId}/settings/${settingId}',
  userAuthorizations: '/admin/users/${userId}/authorizations',
  userAuthorizationsById: '/admin/users/${userId}/authorizations/${id}',
  userPushTokens: '/admin/users/${userId}/push-tokens',
  userPushTokensById: '/admin/users/${userId}/push-tokens/${id}',
  userPinNumbers: '/admin/users/${userId}/pin-numbers',
  userChangePassword: '/admin/users/${userId}/change-password',
  userPinNumbersById: '/admin/users/${userId}/pin-numbers/${id}',
  languages: '/admin/languages',
  languagesById: '/admin/languages/${code}',
  devices: '/admin/devices',
  devicesById: '/admin/devices/${id}',
  drugs: '/admin/drugs',
  drugsById: '/admin/drugs/${id}',
  missingDrugs: '/admin/missing-drugs',
  missingDrugsById: '/admin/missing-drugs/${id}',
  missingDrugsDelete: '/admin/missing-drugs/${id}',
  drugsDelete: '/admin/drugs/${id}',
  translate: '/admin/translate',
  logout: '/auth/logout'
} as const;
