/**
 * Module dependencies.
 */

import { SubmitButton } from 'app/components/atoms/forms/submit-button/submit-button';
import React, { ComponentType, Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { FieldValues, SubmitHandler, UseFormReturn, useWatch } from 'react-hook-form';
import { FormDatePickerField } from './form-components/form-date-picker-field';
import { FormInputField } from './form-components/form-input-field';
import { FormInputNumber } from './form-components/form-input-number';
import { FormSelectField } from './form-components/form-select-field';
import { CrudTemplate, FormFieldType, FormList, FormListField } from './form-types';
import { IfCondition } from './form-widgets/if-condition';
import { Render } from './form-widgets/render';
import { FormCheckBoxField } from './form-components/form-checkbox-field';
import { SwitchCondition } from './form-widgets/switch-condition';
import { FormSliderField } from './form-components/form-slider-field';
import { Columns } from './form-widgets/columns';
import { ListItemForm } from './form-widgets/list-item-form';
import { LoadHooks } from './form-widgets/load-hooks';
import { MdLanguage } from 'react-icons/md';
import { Button } from 'app/components/atoms/button/button';
import styles from './crud.module.less';
import { get, set } from 'lodash';
import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { postTranslate } from 'app/hooks/app-requests/use-translate';
import { useTranslatedKeys } from 'app/components/providers/translated-fields-provider/translated-fields-provider';
import { MdOutlineContentCopy } from 'react-icons/md';
import { FormTimePickerField } from './form-components/form-time-picker-field';
import { Language } from 'app/types/language';

/**
 * `Props` type.
 */

type Props<T> = {
  formType: 'add' | 'edit';
  languages?: Language[] | undefined;
  currentLanguage: string | undefined;
  mainLanguage: string | undefined;
  metadata: any;
  submitClassName?: string;
  template: CrudTemplate<T>;
  submitContainerClassName?: string;
} & (
  | {
      onSubmit: SubmitHandler<FieldValues>;
      formFields: FormList;
      form: UseFormReturn<FieldValues, any>;
      submitLabel: string;
    }
  | {
      onSubmit?: undefined;
      submitLabel?: undefined;
      formFields: FormList;
      form: UseFormReturn<FieldValues, any>;
    }
);

/**
 * Components.
 */

export const components: Record<FormFieldType, ComponentType<any>> = {
  // Forms
  selectField: FormSelectField,
  checkBoxField: FormCheckBoxField,
  sliderField: FormSliderField,
  inputField: FormInputField,
  inputNumberField: FormInputNumber,
  render: Render,
  datePickerField: FormDatePickerField,
  timePickerField: FormTimePickerField,

  // Widgets
  switch: SwitchCondition,
  if: IfCondition,
  loadHooks: LoadHooks,
  columns: Columns,
  listItemForm: ListItemForm
};

/**
 * `FormProps` type.
 */

type FormProps<T> = {
  form: Props<T>['form'];
  onSubmit?: SubmitHandler<FieldValues>;
  submitContainerClassName?: string;
  submitClassName?: string;
  submitLabel: Props<T>['submitLabel'];
  children: JSX.Element;
};

/**
 * `Form` component.
 */

function Form<T>({
  form,
  onSubmit,
  children,
  submitLabel,
  submitContainerClassName,
  submitClassName
}: FormProps<T>): JSX.Element {
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = form;

  if (!onSubmit || !submitLabel) {
    return children;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {children}

      <div className={submitContainerClassName}>
        <SubmitButton className={submitClassName} disabled={isSubmitting} label={submitLabel} />
      </div>
    </form>
  );
}

/**
 * Export `CrudFormRenderProps` type.
 */

export type CrudFormRenderProps<T> = {
  template: CrudTemplate<T>;
  formFields: FormList;
  languages: Language[];
  currentLanguage: string | undefined;
  mainLanguage: string | undefined;
  metadata: any;
  formType: 'add' | 'edit';
  form: UseFormReturn<FieldValues, any>;
};

/**
 * `CrudFormItemRenderProps` type.
 */

type CrudFormItemRenderProps<T> = Pick<
  CrudFormRenderProps<T>,
  'formType' | 'form' | 'currentLanguage' | 'mainLanguage' | 'template' | 'metadata' | 'languages'
> & {
  item: FormListField;
  values: any;
};

/**
 * `CrudFormItemRender` component.
 */

function CrudFormItemRender<T>(props: CrudFormItemRenderProps<T>): JSX.Element | null {
  const { form, template, languages, currentLanguage, mainLanguage, formType, item, values } = props;
  const Component = components[item.type];
  const client = useRequestProvider();
  const { addField } = useTranslatedKeys();
  const { watchChange } = item as any;
  const firstWatch = useRef<boolean>(true);
  const watchValues = useWatch({ control: form.control, name: watchChange?.watchFields ?? [] });
  const hasDisabled = useMemo(() => {
    return typeof (item as any).disabled === 'function'
      ? (item as any).disabled(values ?? {}, props)
      : (item as any).disabled;
  }, [item, props, values]);

  useEffect(() => {
    if (!watchChange || firstWatch.current) {
      firstWatch.current = false;

      return;
    }

    watchChange.onChange(watchValues, form);
  }, [form, watchChange, watchValues]);

  const translateItem = useCallback(async () => {
    const value = get(values, `${item.name}.${languages?.[0]?.code}`);
    const payload = {
      value,
      source: languages?.[0]?.code ?? 'en',
      target: currentLanguage ?? 'en'
    };

    const result = await postTranslate(client, payload);

    form.setValue(`${item.name}.${currentLanguage}`, result.translatedText);
  }, [client, currentLanguage, form, item.name, languages, values]);

  const { translatable, availableInAllLanguages } = item as any;
  const name = translatable ? `${item.name}.${currentLanguage}` : item.name;

  useEffect(() => {
    if (translatable) {
      try {
        addField(item.name);

        if (typeof values[item.name] === 'string') {
          form.setValue(item.name, JSON.parse(values[item.name] ?? '{}'));
        }
      } catch (error) {
        form.setValue(item.name, {});
      }
    }
  }, [addField, form, item.name, translatable, values]);

  if ((item as any).visible && (item as any).visible(values) === false) {
    return null;
  }

  return (
    <div className={styles.wrapperTranslateInput}>
      <Component
        currentLanguage={currentLanguage}
        form={form}
        formType={formType}
        item={{
          ...item,
          name,
          disabled: hasDisabled || (currentLanguage !== mainLanguage && !translatable && !availableInAllLanguages)
        }}
        key={translatable && typeof values[item.name] !== 'string' ? name : (item as any).key ?? (item as any).name}
        languages={languages}
        mainLanguage={mainLanguage}
        template={template}
      />

      {translatable && currentLanguage !== mainLanguage && (
        <div className={styles.wrapperTranslateButton}>
          <Button icon={<MdLanguage />} onClick={translateItem} size={'small'} />

          <Button
            icon={<MdOutlineContentCopy />}
            onClick={() => {
              console.log('>>>>', values);
              form.setValue(`${item.name}.${currentLanguage}`, get(values, `${item.name}.${mainLanguage}`));
            }}
            size={'small'}
          />
        </div>
      )}
    </div>
  );
}

/**
 * `CrudFormRender` component.
 */

export function CrudFormRender<T>(props: CrudFormRenderProps<T>): JSX.Element {
  const { template, currentLanguage, mainLanguage, languages, metadata, form, formType, formFields } = props;
  const values = form.watch();

  return (
    <>
      {formFields.map((item, index) => {
        return (
          <Fragment key={index}>
            <CrudFormItemRender
              currentLanguage={currentLanguage}
              form={form}
              formType={formType}
              item={item}
              key={(item as any).key ?? (item as any).name}
              languages={languages}
              mainLanguage={mainLanguage}
              metadata={metadata}
              template={template}
              values={values}
            />
          </Fragment>
        );
      })}
    </>
  );
}

/**
 * Export `CrudForm` component.
 */

export function CrudForm<T>({
  onSubmit,
  formType,
  submitLabel,
  currentLanguage,
  languages,
  template,
  metadata,
  mainLanguage,
  submitClassName,
  submitContainerClassName,
  form,
  formFields
}: Props<T>): JSX.Element {
  const { translatedFields } = useTranslatedKeys();
  const handleSubmit = useCallback(
    (values: any) => {
      const result = values;

      for (const field of translatedFields) {
        set(result, field, JSON.stringify(get(result, field)));
      }

      if (onSubmit) {
        onSubmit(values);
      }
    },
    [onSubmit, translatedFields]
  );

  return (
    <Form
      form={form}
      onSubmit={handleSubmit}
      submitClassName={submitClassName}
      submitContainerClassName={submitContainerClassName}
      submitLabel={submitLabel}
    >
      <CrudFormRender
        currentLanguage={currentLanguage}
        form={form}
        formFields={formFields}
        formType={formType}
        languages={languages ?? []}
        mainLanguage={mainLanguage}
        metadata={metadata}
        template={template}
      />
    </Form>
  );
}
