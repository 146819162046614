/**
 * Module dependencies.
 */

import { InputField } from 'app/components/atoms/forms/fields/input/input-field';
import React, { useCallback } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { InputFormItem } from '../form-types';

/**
 * `Props` type.
 */

type Props = {
  item: InputFormItem;
  form: UseFormReturn<FieldValues, any>;
};

/**
 * Export `FormInputField` component.
 */

export function FormInputField(props: Props): JSX.Element {
  const { form, item } = props;
  const { control } = form;
  const { placeholder, label, onChange, ...rest } = item;
  const handleChange = useCallback(
    (value: any) => {
      if (onChange) {
        onChange(value, form);
      }
    },
    [form, onChange]
  );

  return (
    <InputField
      inputType={'input'}
      {...rest}
      control={control}
      htmlType={'number'}
      label={label}
      onChange={handleChange}
      placeholder={placeholder ?? placeholder === false ? undefined : label}
    />
  );
}
