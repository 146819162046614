/**
 * Module dependencies.
 */

import React from 'react';
import { ColumnsWidget, CrudTemplate } from '../form-types';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Col, Row } from 'antd';
import { CrudFormRender } from '../crud-form';
import { Language } from 'app/types/language';

/**
 * `Props` type.
 */

type Props<T> = {
  template: CrudTemplate<T>;
  languages: Language[];
  currentLanguage: string | undefined;
  mainLanguage: string | undefined;
  metadata: any;
  formType: 'add' | 'edit';
  name: string;
  item: ColumnsWidget;
  form: UseFormReturn<FieldValues, any>;
};

/**
 * Export `Columns` component.
 */

export function Columns<T>(props: Props<T>): JSX.Element {
  const { form, template, languages, currentLanguage, mainLanguage, metadata, item, formType } = props;
  const { gutter = 16 } = item;

  return (
    <Row
      gutter={gutter}
      style={{
        display: 'flex',
        flex: 1
      }}
    >
      {item.columns.map((column, index) => {
        return (
          <Col key={index} span={column.column}>
            <CrudFormRender
              currentLanguage={currentLanguage}
              form={form}
              formFields={column.children}
              formType={formType}
              languages={languages}
              mainLanguage={mainLanguage}
              metadata={metadata}
              template={template}
            />
          </Col>
        );
      })}
    </Row>
  );
}
