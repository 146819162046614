/**
 * Module dependencies.
 */

import { Title } from 'app/components/atoms/title/title';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Export `DashboardOverview` component.
 */

export function DashboardOverview(): JSX.Element {
  const [translate] = useTranslation();

  return (
    <div>
      <Title>{translate('dashboard.overview.title')}</Title>
    </div>
  );
}
