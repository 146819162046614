// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.biometric-data-module__container--t5_ok {
  max-width: 640px;
}
.biometric-data-module__container--t5_ok .biometric-data-module__card--nbIwT {
  margin-top: 120px;
}
`, "",{"version":3,"sources":["webpack://./components/pages/users/biometric-data/biometric-data.module.less"],"names":[],"mappings":"AAGA;EACE,gBAAA;AAFF;AACA;EAII,iBAAA;AAFJ","sourcesContent":["\n\n\n.container {\n  max-width: 640px;\n\n  .card {\n    margin-top: 120px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `biometric-data-module__container--t5_ok`,
	"card": `biometric-data-module__card--nbIwT`
};
export default ___CSS_LOADER_EXPORT___;
