/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { AxiosInstance } from 'axios';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { getUserInfo } from './use-get-me';
import { AppError } from 'app/core/utils/app-error';
import { cookieStorage } from 'app/core/utils/cookie-storage';

/**
 * `LoginPayload` type.
 */

export type LoginPayload = {
  email: string;
  password: string;
};

/**
 * Login.
 */

async function googleLogin(client: AxiosInstance, payload: LoginPayload): Promise<void> {
  const result = await client.post(resolveAppUrl('login'), payload).then(({ data }) => data);
  const user = await getUserInfo(client);

  if (user.accessType !== 'admin') {
    throw new AppError('invalid_access_type');
  }

  cookieStorage.set('user', user, { path: '/' });

  return result;
}

/**
 * Export `useLogin`.
 */

export function useLogin(options: UseMutationOptions<void, unknown, LoginPayload, unknown>) {
  const client = useRequestProvider();

  return useMutation({
    mutationFn: async (payload: LoginPayload) => {
      return await googleLogin(client, payload);
    },
    ...options
  });
}
