/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { EndpointKey, EndpointValue, resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useCrudRequestInvalidator } from './use-crud-list';
import { CrudTemplate } from 'app/components/organisms/crud/form-types';

/**
 * Add crud.
 */

function addCrud(
  client: AxiosInstance,
  apiEndpoint: EndpointKey | EndpointValue,
  params: any,
  payload: any
): Promise<void> {
  return client.post(resolveAppUrl(apiEndpoint, { interpolations: params }), payload).then(({ data }) => data);
}

/**
 * Export `useCrudAdd`.
 */

export function useCrudAdd<T>(template: CrudTemplate<T>, params: any) {
  const client = useRequestProvider();
  const invalidator = useCrudRequestInvalidator(template.list?.key);

  return useMutation({
    mutationFn: async (payload: any) => {
      if (!template.add?.endpoint) {
        console.error('invalid config ADD', template);
        throw new Error('invalid config ADD');
      }

      const result = await addCrud(client, template.add?.endpoint, params, payload);

      invalidator();

      return result;
    }
  });
}
