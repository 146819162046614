/**
 * Module dependencies.
 */

import { Loading } from 'app/components/atoms/loading/loading';
import React, { useMemo } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { CrudForm } from '../crud-form';
import { CrudTemplate, IfConditionWidget } from '../form-types';
import { Language } from 'app/types/language';

/**
 * `Props` type.
 */

type Props<T> = {
  template: CrudTemplate<T>;
  languages: Language[];
  currentLanguage: string | undefined;
  mainLanguage: string | undefined;
  metadata: any;
  formType: 'add' | 'edit';
  name: string;
  item: IfConditionWidget;
  form: UseFormReturn<FieldValues, any>;
};

/**
 * `IfHookCondition` component.
 */

export function IfHookCondition<T>(props: Props<T>) {
  const { form, template, languages, currentLanguage, mainLanguage, metadata, item, formType } = props;
  const { condition, thenIf, elseIf } = item;
  const { data, isLoading } = (condition as any).hook?.(form, formType);

  const caller = useMemo(() => {
    switch (data) {
      case 'valid':
        return thenIf;
      case 'invalid':
        return elseIf;
      default:
        return undefined;
    }
  }, [data, elseIf, thenIf]);

  if (!caller) {
    return null;
  }

  return (
    <Loading isLoading={isLoading}>
      {typeof caller === 'object' ? (
        <CrudForm
          currentLanguage={currentLanguage}
          form={form}
          formFields={caller}
          formType={formType}
          languages={languages}
          mainLanguage={mainLanguage}
          metadata={metadata}
          template={template}
        />
      ) : (
        (caller as any)?.()
      )}
    </Loading>
  );
}

/**
 * `IfStateCondition` component.
 */

export function IfStateCondition<T>(props: Props<T>) {
  const { form, template, languages, currentLanguage, mainLanguage, metadata, item, formType } = props;
  const { watch } = form;
  const { condition, thenIf, elseIf, watchFields } = item as any;
  const values = watch(watchFields);
  const result = useMemo(() => (condition as any)(values), [condition, values]);

  if (result) {
    if (typeof thenIf === 'object') {
      return (
        <CrudForm
          currentLanguage={currentLanguage}
          form={form}
          formFields={thenIf}
          formType={formType}
          languages={languages}
          mainLanguage={mainLanguage}
          metadata={metadata}
          template={template}
        />
      );
    } else {
      return (thenIf as any)?.();
    }
  } else {
    if (typeof elseIf === 'object') {
      return (
        <CrudForm
          currentLanguage={currentLanguage}
          form={form}
          formFields={elseIf}
          formType={formType}
          languages={languages}
          mainLanguage={mainLanguage}
          metadata={metadata}
          template={template as any}
        />
      );
    } else {
      return (elseIf as any)?.();
    }
  }
}

/**
 * Export `IfCondition` component.
 */

export function IfCondition<T>(props: Props<T>): JSX.Element | null {
  if (typeof (props.item?.condition as any).hook === 'function') {
    return <IfHookCondition {...props} />;
  }

  return <IfStateCondition {...props} />;
}
