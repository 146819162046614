/**
 * Module dependencies.
 */

import { Loading } from 'app/components/atoms/loading/loading';
import React, { useMemo } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { CrudForm } from '../crud-form';
import { CrudTemplate, SwitchConditionWidget } from '../form-types';
import { Language } from 'app/types/language';

/**
 * `Props` type.
 */

type Props<T> = {
  template: CrudTemplate<T>;
  currentLanguage: string | undefined;
  mainLanguage: string | undefined;
  languages: Language[];
  metadata: any;
  formType: 'add' | 'edit';
  name: string;
  item: SwitchConditionWidget;
  form: UseFormReturn<FieldValues, any>;
};

/**
 * `SwitchHookCondition` component.
 */

export function SwitchHookCondition<T>(props: Props<T>) {
  const { form, template, item, languages, currentLanguage, mainLanguage, metadata, formType } = props;
  const { condition, switchCases } = item;
  const { data, isLoading } = (condition as any).hook?.(form, formType);
  const caller = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return switchCases?.[data] ?? switchCases?.default;
  }, [switchCases, data]);

  if (!caller) {
    return null;
  }

  return (
    <Loading isLoading={isLoading}>
      {typeof caller === 'object' ? (
        <CrudForm
          currentLanguage={currentLanguage}
          form={form}
          formFields={caller}
          formType={formType}
          languages={languages}
          mainLanguage={mainLanguage}
          metadata={metadata}
          template={template}
        />
      ) : (
        (caller as any)?.()
      )}
    </Loading>
  );
}

/**
 * `SwitchStateCondition` component.
 */

export function SwitchStateCondition<T>(props: Props<T>) {
  const { form, template, currentLanguage, languages, mainLanguage, metadata, item, formType } = props;
  const { watch } = form;
  const { condition, switchCases } = item;
  const values = watch((item as any).watchFields);
  const result = useMemo(() => (condition as any)(values), [condition, values]);
  const caller = useMemo(() => {
    if (!result) {
      return;
    }

    return switchCases?.[result] ?? switchCases?.default;
  }, [result, switchCases]);

  if (!caller) {
    return undefined;
  }

  if (typeof caller === 'object') {
    return (
      <CrudForm
        currentLanguage={currentLanguage}
        form={form}
        formFields={caller}
        formType={formType}
        languages={languages}
        mainLanguage={mainLanguage}
        metadata={metadata}
        template={template}
      />
    );
  } else {
    return (caller as any)?.();
  }
}

/**
 * Export `SwitchCondition` component.
 */

export function SwitchCondition<T>(props: Props<T>): JSX.Element | null {
  if (typeof (props.item?.condition as any).hook === 'function') {
    return <SwitchHookCondition {...props} />;
  }

  return <SwitchStateCondition {...props} />;
}
