// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-detail-module__wrapper--PrmRE {
  padding: 16px 0;
  border-radius: 16px;
  flex-direction: column;
  display: flex;
}
.user-detail-module__wrapper--PrmRE .user-detail-module__content--UM0Wr {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.user-detail-module__wrapper--PrmRE .user-detail-module__content--UM0Wr .user-detail-module__column--f6xfG {
  border: 1px solid #e0e0e0;
  padding: 16px;
  border-radius: 8px;
  min-width: 400px;
}
.user-detail-module__wrapper--PrmRE .user-detail-module__userInfo--lHQxT {
  max-width: 400px;
}
`, "",{"version":3,"sources":["webpack://./components/organisms/user-detail/user-detail.module.less"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,mBAAA;EACA,sBAAA;EACA,aAAA;AADF;AAHA;EAOI,aAAA;EACA,mBAAA;EACA,SAAA;AADJ;AARA;EAYM,yBAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;AADN;AAdA;EAoBI,gBAAA;AAHJ","sourcesContent":["\n\n.wrapper {\n  padding: 16px 0;\n  border-radius: 16px;\n  flex-direction: column;\n  display: flex;\n\n  .content {\n    display: flex;\n    flex-direction: row;\n    gap: 16px;\n\n    .column {\n      border: 1px solid #e0e0e0;\n      padding: 16px;\n      border-radius: 8px;\n      min-width: 400px;\n    }\n  }\n\n  .userInfo {\n    max-width: 400px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `user-detail-module__wrapper--PrmRE`,
	"content": `user-detail-module__content--UM0Wr`,
	"column": `user-detail-module__column--f6xfG`,
	"userInfo": `user-detail-module__userInfo--lHQxT`
};
export default ___CSS_LOADER_EXPORT___;
