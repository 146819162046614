/**
 * Module dependencies.
 */

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { appRoutes } from 'app/routes';
import { identity, sortBy, pickBy } from 'lodash';
import { CrudListType, CrudRemoveType, CrudTemplate, ListColumns } from '../../../organisms/crud/form-types';
import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import moment from 'moment';
import { TFunction } from 'react-i18next';
import { MissingDrug } from 'app/types/drug';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'app/components/atoms/button/button';
import { AiOutlineSwap } from 'react-icons/ai';
import { resolveAppPath } from 'app/core/utils/url-resolver';

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns<MissingDrug> => [
  {
    title: translate('common.table.columns.code'),
    dataIndex: 'code',
    key: 'code',
    size: '100px'
  },
  {
    title: translate('common.table.columns.name'),
    dataIndex: 'name',
    key: 'name',
    size: '150px'
  },
  {
    title: translate('common.table.columns.notes'),
    dataIndex: 'notes',
    key: 'notes',
    size: '1fr'
  },
  {
    title: translate('common.table.columns.name'),
    dataIndex: 'user.name',
    key: 'user.name'
  },
  {
    title: translate('common.table.columns.email'),
    dataIndex: 'user.email',
    key: 'user.email'
  },
  {
    title: translate('common.table.columns.phoneNumber'),
    dataIndex: 'user.phoneNumber',
    key: 'user.phoneNumber'
  },
  {
    title: translate('common.table.columns.deviceName'),
    dataIndex: 'device.deviceName',
    key: 'device.deviceName',
    size: '150px'
  },
  {
    title: translate('common.table.columns.operatingSystem'),
    dataIndex: 'device.operatingSystem',
    key: 'device.operatingSystem',
    size: '150px'
  },
  {
    title: translate('common.table.columns.appVersion'),
    dataIndex: 'device.appVersion',
    key: 'device.appVersion',
    size: '150px'
  },
  {
    title: translate('common.table.columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    size: '200px',
    render: item => moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')
  },
  {
    title: translate('common.table.columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    size: '200px',
    render: item => moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: translate('common.labels.search')
  }
];

/**
 * List.
 */

const list = (translate: TFunction): CrudListType<MissingDrug> => ({
  columns: listColumns(translate),
  filters: listFilters(translate),
  resolveParams: (params: any, item?: MissingDrug) => {
    return {
      id: params?.id ?? item?.id
    };
  },
  expandedDataKey: item => sortBy(item.transactions, 'date').reverse(),
  route: appRoutes.dashboard.medical.missingDrugs,
  addButton: translate('common.actions.add'),
  canEdit: true,
  canRemove: true,
  key: ['missingDrugs'],
  normalizeFilters: (data: any) => {
    const { ...filters } = data.filters;
    const result: any = {
      filters: pickBy(filters, identity),
      order: []
    };

    return result;
  },
  renderActions: (item: any) => {
    const link = resolveAppPath(appRoutes.dashboard.medical.missingDrugsEdit, { id: item.id });

    return (
      <>
        <Link
          onClick={event => {
            event.stopPropagation();
          }}
          to={link}
        >
          <Button icon={<AiOutlineSwap />} size={'small'} />
        </Link>
      </>
    );
  },
  normalizeData: (result: any) => {
    return result;
  },
  pagination: {
    pageSize: 25,
    pageSizeOptions: [10, 25, 50, 100, 500, 1000]
  },
  endpoint: apiEndpoints.missingDrugs,
  endpointById: apiEndpoints.missingDrugsById
});

/**
 * Remove.
 */

const remove = (): CrudRemoveType<MissingDrug> => ({
  endpoint: apiEndpoints.missingDrugsDelete
});

/**
 * Config.
 */

export function createMissingDrugsTemplate(translate: TFunction): CrudTemplate<MissingDrug> {
  return {
    list: list(translate),
    remove: remove()
  };
}
