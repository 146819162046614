/**
 * Export `AppError` class.
 */

export class AppError extends Error {
  /**
   * Properties.
   */

  code: string;
  params: any;
  response: any;

  /**
   * Constructor.
   */

  constructor(code: string, params?: any) {
    super(code);

    this.code = code;
    this.params = params;
    this.response = {
      data: {
        data: {
          code,
          ...params
        }
      }
    };
  }
}
