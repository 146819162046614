/**
 * Module dependencies.
 */

import { Button } from 'app/components/atoms/button/button';
import { useLogout } from 'app/hooks/app-requests/use-logout';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import { Title } from 'app/components/atoms/title/title';
import moment from 'moment';
import { Field } from 'app/components/organisms/field/field';
import { cookieStorage } from 'app/core/utils/cookie-storage';
import { ShowChangePassword } from '../../users/user-detail-overview/show-change-password';

/**
 * Export `SettingsProfile` component.
 */

export function SettingsProfile(): JSX.Element {
  const [translate] = useTranslation();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const { mutate: logout } = useLogout();
  const user = useMemo(() => {
    return cookieStorage.get('user');
  }, []);

  return (
    <>
      <Title>{'Profile'}</Title>

      <div style={{ maxWidth: 500 }}>
        <Field title={translate('common.labels.name')} value={user.name} />

        <Field title={translate('common.labels.phoneNumber')} value={user.phoneNumber} />

        <Field title={translate('common.labels.email')} value={user.email} />

        <Field title={translate('common.labels.address')} value={user.address} />

        <Field title={translate('common.labels.postalCode')} value={user.postalCode} />

        <Field title={translate('common.labels.city')} value={user.city} />

        <Field title={translate('common.labels.countryCode')} value={user.countryCode} />

        <Field title={translate('common.labels.birthDate')} value={user.birthDate} />

        <Field title={translate('common.labels.gender')} value={user.gender} />

        <Field title={translate('common.labels.language')} value={user.language} />

        <Field title={translate('common.labels.nationality')} value={user.nationality} />

        <Field
          title={translate('common.labels.createdAt')}
          value={moment(user.createdAt).format('YYYY-MM-DD - HH:mm:ss')}
        />

        <Field
          title={translate('common.labels.updatedAt')}
          value={moment(user.updatedAt).format('YYYY-MM-DD - HH:mm:ss')}
        />
      </div>

      <div
        style={{
          gap: 8,
          display: 'flex',
          marginTop: 32
        }}
      >
        <Button onClick={() => logout()} type={'primary'}>
          {translate('common.actions.logout')}
        </Button>

        <Button
          onClick={() => {
            setShowChangePassword(true);
          }}
        >
          {translate('common.actions.changePassword')}
        </Button>
      </div>

      <ShowChangePassword isOpen={showChangePassword} onClose={() => setShowChangePassword(false)} userId={user.id} />
    </>
  );
}
