/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { RequestOptions } from 'app/types/network';
import { DialCode } from 'app/types/dial-code';

/**
 * Get dial codes.
 */

function getDialCodes(client: AxiosInstance, options?: RequestOptions): Promise<DialCode[]> {
  return client
    .get(
      resolveAppUrl('dialCodes', {
        params: options
      })
    )
    .then(({ data }) => data?.data);
}

/**
 * Export `useGetDialCodes`.
 */

export function useGetDialCodes(options?: RequestOptions) {
  const client = useRequestProvider();

  return useQuery(['dialCodes'], {
    queryFn: async () => {
      return await getDialCodes(client, options);
    }
  });
}
