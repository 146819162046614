/**
 * Module dependencies.
 */

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { appRoutes } from 'app/routes';
import { identity, pickBy } from 'lodash';
import { CrudListType, CrudRemoveType, CrudTemplate, ListColumns } from '../../../organisms/crud/form-types';

import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import { TFunction } from 'react-i18next';
import { UserPushToken } from 'app/types/user-push-token';
import moment from 'moment';

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns<UserPushToken> => [
  {
    title: translate('common.table.columns.key'),
    dataIndex: 'pushToken',
    key: 'pushToken',
    size: '1fr'
  },
  {
    title: translate('common.table.columns.appVersion'),
    dataIndex: 'appVersion',
    key: 'appVersion'
  },
  {
    title: translate('common.table.columns.deviceName'),
    dataIndex: 'deviceName',
    key: 'deviceName'
  },
  {
    title: translate('common.table.columns.deviceId'),
    dataIndex: 'deviceId',
    key: 'deviceId'
  },
  {
    title: translate('common.table.columns.operatingSystem'),
    dataIndex: 'operatingSystem',
    key: 'operatingSystem'
  },
  {
    title: translate('common.table.columns.lastRefreshDate'),
    dataIndex: 'lastRefreshDate',
    key: 'lastRefreshDate',
    render: item => moment(item.lastRefreshDate).format('DD-MM-YYYY HH:mm:ss')
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: translate('common.labels.search')
  },
  {
    type: 'selectSearch',
    name: 'accessType',
    inline: true,
    label: translate('common.labels.accessType'),
    showSearch: true,
    allowClear: true,
    options: [
      {
        label: translate('common.labels.user'),
        value: 'user'
      },
      {
        label: translate('common.labels.backOffice'),
        value: 'backOffice'
      },
      {
        label: translate('common.labels.admin'),
        value: 'admin'
      }
    ]
  }
];

/**
 * List.
 */

function list(translate: TFunction): CrudListType<UserPushToken> {
  return {
    columns: listColumns(translate),
    filters: listFilters(translate),
    route: appRoutes.dashboard.users.detail.pushTokens,
    addButton: translate('common.actions.add'),
    canEdit: true,
    canRemove: true,
    resolveParams: (params: any, item?: UserPushToken) => {
      return {
        userId: params.userId,
        deviceId: params.id ?? item?.deviceId
      };
    },
    key: ['userPushTokens'],
    normalizeFilters: (data: any) => {
      const { ...filters } = data.filters;
      const result: any = {
        filters: pickBy(filters, identity),
        order: []
      };

      return result;
    },
    normalizeData: (result: any) => {
      return result;
    },
    pagination: {
      pageSize: 25,
      pageSizeOptions: [10, 25, 50, 100, 500, 1000]
    },
    endpoint: apiEndpoints.userPushTokens,
    endpointById: apiEndpoints.userPushTokensById
  };
}

/**
 * Remove.
 */

const remove = (): CrudRemoveType<UserPushToken> => ({
  resolveParams: (params: any, item?: UserPushToken) => {
    return {
      userId: params.userId,
      id: params.id ?? item?.deviceId
    };
  },
  endpoint: apiEndpoints.userPushTokensById
});

/**
 * Config.
 */

export function createUserPushTokensTemplate(translate: TFunction): CrudTemplate<UserPushToken> {
  return {
    list: list(translate),
    remove: remove()
  };
}
