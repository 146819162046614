/**
 * Module dependencies.
 */

import { SelectField } from 'app/components/atoms/forms/fields/select/select-field';
import { searchOnInput } from 'app/core/utils/filter-utils';
import { identity, set } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { SelectSearch } from '../form-filters-type';
import { FilterOptions } from './types';
import { useParams } from 'react-router-dom';

/**
 * `ByHook` component.
 */

function ByHook(props: FilterOptions<SelectSearch>) {
  const { disabled, form, item, className, fieldClassName } = props;
  const { control } = form;
  const params = useParams();
  const { watch } = form;
  const {
    label,
    name,
    placeholder,
    style,
    allowClear,
    defaultValue,
    filterOption = searchOnInput,
    showSearch = false,
    options
  } = item;

  const { hookProps, normalize, hook } = options as any;
  const [values, setValues] = useState<any>();

  useEffect(() => {
    const subscription = watch(setValues);

    return () => subscription.unsubscribe();
  }, [watch]);

  const hookPropsParams = hookProps?.(values, params) ?? [];
  const { data } = hook?.(...hookPropsParams) ?? { data: options };
  const normalizer = normalize ?? identity;
  const optionValues = useMemo(() => normalizer(data?.results ?? data), [data, normalizer]);

  set(form, ['cache', 'hooks', name], {
    data,
    optionValues
  });

  return (
    <SelectField
      allowClear={allowClear}
      className={className}
      control={control}
      defaultValue={typeof defaultValue === 'function' ? defaultValue(optionValues) : defaultValue}
      disabled={disabled}
      fieldClassName={fieldClassName}
      filterOption={filterOption}
      label={label}
      name={name}
      options={optionValues ?? []}
      placeholder={placeholder ?? placeholder === false ? undefined : label}
      showSearch={showSearch}
      style={style}
    />
  );
}

/**
 * `ByStatic` component.
 */

function ByStatic(props: FilterOptions<SelectSearch>) {
  const { disabled, form, item } = props;
  const { name, label, placeholder, defaultValue, style, allowClear, filterOption, showSearch, options } = item;
  const { control } = form;

  return (
    <SelectField
      allowClear={allowClear}
      control={control}
      defaultValue={typeof defaultValue === 'function' ? defaultValue(options) : defaultValue}
      disabled={disabled}
      filterOption={filterOption}
      label={label}
      name={name}
      options={options as any}
      placeholder={placeholder ?? placeholder === false ? undefined : label}
      showSearch={showSearch}
      style={style}
    />
  );
}

/**
 * Export `FilterSelectField` component.
 */

export function FilterSelectField(props: FilterOptions<SelectSearch>): JSX.Element {
  if ((props.item.options as any)?.hook) {
    return <ByHook {...props} />;
  }

  return <ByStatic {...props} />;
}
