// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-module__wrapper--UaG0N {
  background-color: gray;
}
.home-module__login--bY9bF {
  width: 320px;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-module__login--bY9bF .home-module__layout--QNQgl {
  justify-content: center;
  align-items: center;
}
.home-module__login--bY9bF .home-module__layout--QNQgl .home-module__title--__U0L {
  text-align: center;
}
.home-module__login--bY9bF .home-module__layout--QNQgl .home-module__submitContainer--KyXxr {
  justify-content: center;
  display: flex;
}
.home-module__login--bY9bF .home-module__layout--QNQgl .home-module__box--ihjEB {
  background-color: white;
  min-width: 480px;
  min-height: 200px;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #000000;
  box-shadow: 0 0px 30px rgba(255, 255, 255, 0.2);
}
`, "",{"version":3,"sources":["webpack://./components/pages/home/home.module.less"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;AAGA;EACE,YAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AADF;AALA;EASI,uBAAA;EACA,mBAAA;AADJ;AATA;EAaM,kBAAA;AADN;AAZA;EAiBM,uBAAA;EACA,aAAA;AAFN;AAhBA;EAsBM,uBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,yBAAA;EACA,+CAAA;AAHN","sourcesContent":[".wrapper {\n  background-color: gray;\n}\n\n\n.login {\n  width: 320px;\n  height: 100vh;\n  width: 100vw;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  .layout {\n    justify-content: center;\n    align-items: center;\n\n    .title {\n      text-align: center;\n    }\n\n    .submitContainer {\n      justify-content: center;\n      display: flex;\n    }\n\n    .box {\n      background-color: white;\n      min-width: 480px;\n      min-height: 200px;\n      border-radius: 8px;\n      padding: 16px;\n      border: 1px solid #000000;\n      box-shadow: 0 0px 30px rgba(255, 255, 255, 0.2);\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `home-module__wrapper--UaG0N`,
	"login": `home-module__login--bY9bF`,
	"layout": `home-module__layout--QNQgl`,
	"title": `home-module__title--__U0L`,
	"submitContainer": `home-module__submitContainer--KyXxr`,
	"box": `home-module__box--ihjEB`
};
export default ___CSS_LOADER_EXPORT___;
