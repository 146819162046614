/**
 * Module dependencies.
 */

import axios, { AxiosInstance } from 'axios';
import { cookieStorage } from './cookie-storage';

/**
 * Refresh token.
 */

async function refreshToken() {
  const instance = axios.create({ withCredentials: true });

  try {
    await instance.post('/api/auth/refresh-token');
  } catch (error) {
    cookieStorage.remove('token');
    cookieStorage.remove('user');
    cookieStorage.remove('refreshToken');
  }
}

/**
 * Export `createAxiosClient`.
 */

export function createAxiosClient(): AxiosInstance {
  const instance = axios.create({
    withCredentials: true
  });

  instance.interceptors.request.use(config => {
    return config;
  });

  instance.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      if (error.response?.status === 401 && error.response?.data?.data?.code === 'invalid_token') {
        console.log('error config', error.config);

        await refreshToken();

        return await axios(error.config);
      }

      return Promise.reject(error);
    }
  );

  return instance;
}
