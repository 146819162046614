/**
 * Module dependencies.
 */

import { Button } from 'app/components/atoms/button/button';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { CrudTemplate } from '../form-types';
import styles from '../crud.module.less';
import { FiltersForm } from './filters-form';
import { resolveAppPath } from 'app/core/utils/url-resolver';

/**
 * `Props` type.
 */

type Props<T> = {
  template: CrudTemplate<T>;
  appendFilters: (values: any) => void;
  refresh: () => Promise<any>;
  params: any;
  hasNested: boolean;
  children?: JSX.Element | null;
};

/**
 * Export `CrudFilters` component.
 */

export function CrudFilters<T>(props: Props<T>): JSX.Element {
  const { children, template, hasNested, refresh, appendFilters, params } = props;
  const list = useMemo(() => template?.list, [template?.list]);
  const [searchParams] = useSearchParams();
  const filters = useMemo(() => list?.filters ?? [], [list?.filters]);
  const handleAppendFilters = useCallback(
    (data: any) => {
      appendFilters(template.list?.normalizeFilters?.(data));
    },
    [appendFilters, template.list]
  );

  useEffect(() => {
    if (hasNested) {
      appendFilters({});
    }
  }, [appendFilters, hasNested]);

  return (
    <div className={styles.header}>
      <FiltersForm
        appendFilters={handleAppendFilters}
        defaultQueryString={template?.list?.defaultQueryString}
        filters={filters}
        hasNested={hasNested}
        queryStorageKey={template?.list?.endpoint}
      >
        <div className={styles.headerActions}>
          {children}

          {template?.list?.extraActions?.({ refresh })}

          {!!template?.add?.route && (
            <div className={styles.addButton}>
              <Link
                to={
                  template?.list?.interpolateAddLink?.(template, searchParams) ??
                  resolveAppPath(template?.add.route, params)
                }
              >
                <Button size={'large'} type={'primary'}>
                  {template.list?.addButton}
                </Button>
              </Link>
            </div>
          )}
        </div>
      </FiltersForm>
    </div>
  );
}
