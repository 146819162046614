/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

/**
 * `User` type.
 */

type User = {
  email: string;
  accessType: 'admin' | 'backOffice';
};

/**
 * `Options` type.
 */

type Options = {
  enabled?: boolean;
};

/**
 * Get user info.
 */

export function getUserInfo(client: AxiosInstance): Promise<User> {
  return client.get(resolveAppUrl('userInfo'), { withCredentials: true }).then(({ data }) => data?.data);
}

/**
 * Export `useCryptoOverviewInvalidator`.
 */

export function useCryptoOverviewInvalidator() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries(['userInfo']);
  }, [queryClient]);
}

/**
 * Export `useUserInfo`.
 */

export function useUserInfo(options?: Options) {
  const client = useRequestProvider();

  return useQuery(['userInfo'], {
    queryFn: async () => {
      return await getUserInfo(client);
    },
    enabled: options?.enabled
  });
}
