/**
 * Module dependencies.
 */

import React, { createContext, useCallback, useContext, useState } from 'react';
import { uniq } from 'lodash';

/**
 * `Props` type.
 */

type Props = {
  children: JSX.Element;
};

/**
 * `StorageContext` type.
 */

type StorageContext = {
  translatedFields: string[];
  addField: (field: string) => void;
};

/**
 * Translated fields context.
 */

const TranslatedFieldsProviderContext = createContext<StorageContext>({
  translatedFields: [],
  addField: () => null
});

/**
 * Export `useTranslatedKeys`.
 */

export function useTranslatedKeys() {
  return useContext(TranslatedFieldsProviderContext);
}

/**
 * Export `TranslatedFieldsProvider` component.
 */

export function TranslatedFieldsProvider({ children }: Props): JSX.Element {
  const [translatedFields, setTranslatedFields] = useState<string[]>([]);

  const addField = useCallback((fieldName: string) => {
    setTranslatedFields((items: string[]) => {
      return uniq([...items, fieldName]);
    });
  }, []);

  return (
    <TranslatedFieldsProviderContext.Provider value={{ addField, translatedFields }}>
      {children}
    </TranslatedFieldsProviderContext.Provider>
  );
}
