/**
 * Module dependencies.
 */

import { apiEndpoints } from 'app/core/config/api-endpoints';
import { appRoutes } from 'app/routes';
import { identity, pickBy } from 'lodash';
import { CrudListType, CrudRemoveType, CrudTemplate, ListColumns } from '../../../organisms/crud/form-types';
import { ListFilters } from 'app/components/organisms/crud/form-filters/form-filters-type';
import moment from 'moment';
import { TFunction } from 'react-i18next';
import { UserAuthorizations } from 'app/types/user-authorizations';
import { UserLabel } from 'app/components/molecules/user-label/user-label';
import React from 'react';
import { UserType } from 'app/components/molecules/user-type/user-type';

/**
 * List labels.
 */

const listColumns = (translate: TFunction): ListColumns<UserAuthorizations> => [
  {
    title: translate('common.table.columns.id'),
    dataIndex: 'id',
    key: 'id',
    size: '',
    render: item => `...${item.id.slice(-5)}`
  },
  {
    title: translate('common.table.columns.type'),
    dataIndex: 'status',
    key: 'status',
    size: '',
    render: item => <UserType item={item} />
  },
  {
    title: translate('common.table.columns.userFrom'),
    dataIndex: 'userFrom',
    key: 'userFrom',
    size: '',
    render: item => <UserLabel user={item._from} />
  },
  {
    title: translate('common.table.columns.userTo'),
    dataIndex: 'userTo',
    key: 'userTo',
    size: '',
    render: item => <UserLabel user={item._to} />
  },
  {
    title: translate('common.table.columns.status'),
    dataIndex: 'status',
    key: 'status',
    size: ''
  },
  {
    title: translate('common.table.columns.scopes'),
    dataIndex: 'scopes',
    key: 'scopes',
    size: ''
  },
  {
    title: translate('common.table.columns.dateOfRequest'),
    dataIndex: 'dateOfRequest',
    key: 'dateOfRequest',
    size: '',
    render: item => moment(item.dateOfRequest).format('YYYY-DD-MM HH:mm:ss')
  },
  {
    title: translate('common.table.columns.expirationDate'),
    dataIndex: 'expirationDate',
    key: 'expirationDate',
    size: '',
    render: item => moment(new Date(parseFloat(item.expirationDate))).format('YYYY-DD-MM HH:mm:ss')
  },
  {
    title: translate('common.table.columns.lastAction'),
    dataIndex: 'lastAction',
    key: 'lastAction',
    size: ''
  },
  {
    title: translate('common.table.columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    size: '200px',
    render: item => moment(item.createdAt).format('YYYY-DD-MM HH:mm:ss')
  },
  {
    title: translate('common.table.columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    size: '200px',
    render: item => moment(item.createdAt).format('YYYY-DD-MM HH:mm:ss')
  }
];

/**
 * List filters.
 */

const listFilters = (translate: TFunction): ListFilters => [
  {
    type: 'inputSearch',
    inline: true,
    name: 'search',
    label: translate('common.labels.search')
  },
  {
    type: 'selectSearch',
    name: 'accessType',
    inline: true,
    label: translate('common.labels.accessType'),
    showSearch: true,
    allowClear: true,
    options: [
      {
        label: translate('common.labels.user'),
        value: 'user'
      },
      {
        label: translate('common.labels.backOffice'),
        value: 'backOffice'
      },
      {
        label: translate('common.labels.admin'),
        value: 'admin'
      }
    ]
  }
];

/**
 * List.
 */

function list(translate: TFunction): CrudListType<UserAuthorizations> {
  return {
    columns: listColumns(translate),
    filters: listFilters(translate),
    route: appRoutes.dashboard.users.detail.authorizations,
    addButton: translate('common.actions.add'),
    canEdit: true,
    canRemove: true,
    resolveParams: (params: any, item?: UserAuthorizations) => {
      return {
        userId: params.userId,
        settingId: params.settingId ?? item?.id
      };
    },
    key: ['userAuthorizations'],
    normalizeFilters: (data: any) => {
      const { ...filters } = data.filters;
      const result: any = {
        filters: pickBy(filters, identity),
        order: []
      };

      return result;
    },
    normalizeData: (result: any) => {
      return result;
    },
    pagination: {
      pageSize: 25,
      pageSizeOptions: [10, 25, 50, 100, 500, 1000]
    },
    endpoint: apiEndpoints.userAuthorizations,
    endpointById: apiEndpoints.userAuthorizationsById
  };
}

/**
 * Remove.
 */

const remove = (): CrudRemoveType<UserAuthorizations> => ({
  resolveParams: (params: any, item?: UserAuthorizations) => {
    debugger;

    const result = {
      userId: params.userId,
      id: item?.id
    };

    return result;
  },
  endpoint: apiEndpoints.userAuthorizationsById
});

/**
 * Config.
 */

export function createUserAuthorizationsTemplate(translate: TFunction): CrudTemplate<UserAuthorizations> {
  return {
    list: list(translate),
    remove: remove()
  };
}
