/**
 * Module dependencies.
 */

import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './home.module.less';
import { appRoutes } from 'app/routes';
import { cookieStorage } from 'app/core/utils/cookie-storage';
import { Title } from 'app/components/atoms/title/title';
import { CrudForm } from 'app/components/organisms/crud/crud-form';
import { FormList } from 'app/components/organisms/crud/form-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { requiredRule } from 'app/core/utils/field-rules';
import { LoginPayload, useLogin } from 'app/hooks/app-requests/use-login';

/**
 * Export `Home` component.
 */

export function Home(): JSX.Element {
  const navigate = useNavigate();
  const [translate] = useTranslation();
  const { mutate: login } = useLogin({
    onSuccess: () => {
      navigate(appRoutes.dashboard.home.base);
    }
  });

  useEffect(() => {
    const user = cookieStorage.get('user');

    if (user) {
      navigate(appRoutes.dashboard.home.base);
    }
  }, [navigate]);

  const onSubmit = useCallback(
    async (data: LoginPayload) => {
      const result = await login(data);

      console.log(result);
    },
    [login]
  );

  const formFields: FormList = [
    {
      type: 'inputField',
      name: 'email',
      label: translate('common.labels.email'),
      rules: requiredRule(translate)
    },
    {
      inputType: 'password',
      type: 'inputField',
      name: 'password',
      label: translate('common.labels.password'),
      rules: requiredRule(translate)
    }
  ];

  const form = useForm({
    mode: 'all'
  });

  return (
    <div className={styles.login}>
      <div className={styles.layout}>
        <Title className={styles.title}>{translate('home.title')}</Title>

        <div className={styles.box}>
          <CrudForm
            currentLanguage={'pt'}
            form={form as any}
            formFields={formFields}
            formType={'add'}
            mainLanguage={'pt'}
            metadata={{}}
            onSubmit={onSubmit as any}
            submitContainerClassName={styles.submitContainer}
            submitLabel={translate('common.actions.login')}
            template={{}}
          />
        </div>
      </div>
    </div>
  );
}
