/**
 * Module dependencies.
 */

import { Popconfirm } from 'antd';
import { resolveAppPath } from 'app/core/utils/url-resolver';
import { useCrudDelete } from 'app/hooks/app-requests/crud/use-crud-delete';
import { useCrudList } from 'app/hooks/app-requests/crud/use-crud-list';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CrudTemplate } from './form-types';
import { castArray, merge } from 'lodash';
import styles from './crud.module.less';
import { Table } from 'app/components/organisms/table/table';
import { Button } from 'app/components/atoms/button/button';
import { CrudFilters } from './form-filters/crud-filters';
import { Wrapper } from 'app/components/atoms/wrapper/wrapper';
import { Loading } from 'app/components/atoms/loading/loading';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useDebounceLoading } from 'app/hooks/use-debounce';
import classnames from 'classnames';
import { useQueryParamsStorageProvider } from 'app/components/providers/query-params-storage/query-params-storage';
import { defaultResolveParams } from 'app/core/utils/params';
import { Select } from 'app/components/atoms/forms/fields/select/select';
import { Country } from 'app/components/atoms/country/country';
import { useGetLanguages } from 'app/hooks/app-requests/use-get-languages';
import { Language } from 'app/types/language';
import { cookieStorage } from 'app/core/utils/cookie-storage';

/**
 * `Props` type.
 */

type Props<T> = {
  template: CrudTemplate<T>;
  nestedParams?: any;
};

/**
 * `ExpandedRowProps` type.
 */

type ExpandedRowProps = {
  item: any;
  extras: any;
  expanded: boolean;
  currentLanguage: string;
};

/**
 * `ExpandedRow` component.
 */

function ExpandedRow({ extras, item, currentLanguage, expanded }: ExpandedRowProps) {
  const list = extras.template.list;
  const columns = list.expandedColumns;
  const rowKey = list.expandedRowKey ?? 'id';
  const dataKey = list.expandedDataKey;
  const data = useMemo(() => {
    if (typeof dataKey === 'function') {
      return dataKey(item);
    }

    return item[dataKey];
  }, [dataKey, item]);

  if (!expanded) {
    return null;
  }

  return (
    <Table
      className={styles.table}
      columns={columns}
      currentLanguage={currentLanguage}
      dataSource={data}
      rowKey={rowKey}
    />
  );
}

/**
 * `ExpandedRowTable` component.
 */

function ExpandedRowTable({ extras, item, expanded }: { item: any; extras: any; expanded: boolean }) {
  const template = extras.template.list.expandedTemplate;
  const localList = !template.list.endpoint;
  const nestedParams = useMemo(() => {
    return {
      ...(template.list?.nestedParams?.(item) ?? {}),
      ...(localList ? { item } : {})
    };
  }, [item, localList, template.list]);

  if (!expanded) {
    return null;
  }

  if (!localList && !nestedParams) {
    throw new Error('`nestedParams` is required for `expandedTemplate`');
  }

  return (
    <div className={styles.nestedTable}>
      <CrudList nestedParams={nestedParams} template={template} />
    </div>
  );
}

/**
 * Export `CrudList` component.
 */

export function CrudList<T>({ template, nestedParams }: Props<T>): JSX.Element {
  const [translate] = useTranslation();
  const routerParams = useParams();
  const { setPageParams } = useQueryParamsStorageProvider();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const params = useMemo(
    () => ({
      ...routerParams,
      ...(nestedParams ?? {})
    }),
    [nestedParams, routerParams]
  );

  const [pageSize, changePageSize] = useState(template.list?.pagination?.pageSize ?? 10);
  const lastTotal = useRef<number | undefined>();

  const { mutate: deleteAction, isLoading } = useCrudDelete(template, params);
  const [query, setQuery] = useState<any>();
  const { data, refetch, ...restQuery } = useCrudList<T>(template, params, query);
  const appendFilters = useCallback(
    (values: any) => {
      const query = merge({}, template.list?.append ?? {}, values);

      if (template.list?.pagination) {
        query.page = searchParams.get('page') ?? 1;
        query.pageSize = pageSize;
      }

      setQuery((data: any) => {
        if (JSON.stringify(data) === JSON.stringify(query)) {
          return data;
        }

        if (template?.list?.endpoint) {
          setPageParams(template?.list?.endpoint, query);
        }

        return query;
      });
    },
    [pageSize, searchParams, setPageParams, template.list?.append, template.list?.endpoint, template.list?.pagination]
  );

  const page = searchParams.get('page') ?? 1;
  const isLoadingData = useDebounceLoading(restQuery.isFetching, 50, 350);

  useEffect(() => {
    if (!(data as any)?.total) {
      return;
    }

    if (!lastTotal.current) {
      lastTotal.current = (data as any).total;

      return;
    }
    if (lastTotal.current !== (data as any)?.total) {
      lastTotal.current = (data as any).total;

      setSearchParams(params => {
        params.set('page', '1');

        return params;
      });
    }
  }, [data, setSearchParams]);

  useEffect(() => {
    setQuery((query: any) => ({
      ...query,
      page
    }));
  }, [page, refetch]);

  useEffect(() => {
    if (query) {
      refetch();
    }
  }, [query, refetch]);

  const columns = useMemo(() => {
    const columns = [...(template.list?.columns ?? [])];
    const hasRenderActions = !!template.list?.renderActions;
    const canEdit = !!template?.list?.canEdit;
    const canRemove = !!template.list?.canRemove;

    if (!hasRenderActions && !canEdit && !canRemove) {
      return columns;
    }

    columns.push({
      title: translate('common.table.columns.actions'),
      key: 'actions',
      style: columns?.[0].style,
      render: (item: any) => {
        const resolveParams = template.list?.resolveParams ?? defaultResolveParams;
        const to = !template.edit ? null : resolveAppPath(template.edit?.route as any, resolveParams(params, item));
        const _canEdit =
          typeof template.list?.canEdit === 'function' ? template.list?.canEdit(item) : template.list?.canEdit;
        const _canRemove =
          typeof template.list?.canRemove === 'function' ? template.list?.canRemove(item) : template.list?.canRemove;

        return (
          <div className={styles.actions}>
            {_canEdit && template?.edit && to && (
              <Link
                onClick={event => {
                  event.stopPropagation();
                }}
                to={to}
              >
                <Button icon={<EditOutlined />} size={'small'} />
              </Link>
            )}

            {_canRemove && template.remove && (
              <Popconfirm
                okButtonProps={{ loading: isLoading }}
                {...{ onClick: (event: any) => event.stopPropagation() }}
                onConfirm={(event: any) => {
                  event.stopPropagation();
                  deleteAction(item);
                }}
                title={translate('removeAction')}
              >
                <Button danger icon={<DeleteOutlined />} size={'small'} />
              </Popconfirm>
            )}

            {template.list?.renderActions?.(item, template, refetch)}
          </div>
        );
      }
    });

    console.log(columns);

    return columns;
  }, [deleteAction, isLoading, params, refetch, template, translate]);

  const WrapperComponent = template.list?.WrapperComponent ?? Wrapper;
  const expandedRowRender = useMemo(() => {
    if (template?.list?.expandedColumns) {
      return ExpandedRow;
    }

    if (template?.list?.expandedTemplate) {
      return ExpandedRowTable;
    }
  }, [template?.list?.expandedColumns, template?.list?.expandedTemplate]);

  const onClickLine = useMemo(() => {
    if (!template.list?.clickLine) {
      return undefined;
    }

    return (item: any) => {
      template?.list?.clickLine?.(item, { navigate });
    };
  }, [navigate, template.list]);

  const user = cookieStorage.get('user');
  const [currentLanguage, setCurrentLanguage] = useState(user.language);
  const { data: availableLanguages } = useGetLanguages();
  const languages = useMemo(() => {
    if (typeof template?.list?.languages === 'string' && template?.list?.languages === '*') {
      setCurrentLanguage(availableLanguages?.[0].code);
      return availableLanguages ?? [];
    }

    const langs = castArray(template?.list?.languages ?? []).map(item => item.toLowerCase());

    return (availableLanguages ?? []).filter(item => {
      return langs.includes(item.code.toLowerCase());
    });
  }, [availableLanguages, template?.list?.languages]);

  return (
    <WrapperComponent
      className={styles.wrapper}
      isLoading={restQuery.isLoading || restQuery.isFetching}
      refetch={refetch}
      template={template}
    >
      <CrudFilters
        appendFilters={appendFilters}
        hasNested={!!nestedParams}
        params={params}
        refresh={refetch}
        template={template}
      >
        {!template?.list?.languages ? null : (
          <>
            <Select
              name={'language'}
              onChange={setCurrentLanguage}
              options={languages?.map((item: Language) => ({
                value: item.code,
                searchValue: item.name,
                minimal: <Country item={item} />,
                label: <Country item={item} />
              }))}
              placeholder={translate('common.labels.language')}
              style={{ marginTop: 8, minWidth: 200 }}
              value={currentLanguage}
            />
          </>
        )}
      </CrudFilters>

      <Loading isLoading={isLoading}>
        <Table
          changePageSize={changePageSize}
          className={styles.table}
          columns={columns}
          currentLanguage={currentLanguage}
          dataSource={(data as any)?.results ?? []}
          expandedExtras={{ template }}
          expandedRowRender={expandedRowRender}
          footer={template?.list?.renderLastLine?.({
            data: (data as any)?.results,
            total: (data as any)?.total,
            columns,
            template,
            refetch
          })}
          isLoading={isLoadingData}
          onClickLine={onClickLine}
          pagination={
            template.list?.pagination && {
              total: (data as any)?.total,
              pageSize: pageSize,
              pageSizeOptions: template.list?.pagination?.pageSizeOptions
            }
          }
          rowKey={'id'}
          style={template.list?.style}
          wrapperClassName={classnames({
            [styles.nestedTable]: !!nestedParams
          })}
        />
      </Loading>
    </WrapperComponent>
  );
}
