/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { EndpointKey, EndpointValue, resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { RequestOptions } from 'app/types/network';
import { CrudTemplate } from 'app/components/organisms/crud/form-types';
import { identity } from 'lodash';

/**
 * `List` type.
 */

export type List<T> = {
  total: number;
  results: T[];
};

/**
 * Get data.
 */

function getData<T>(
  client: AxiosInstance,
  apiEndpoint: EndpointKey | EndpointValue,
  params: any,
  options: RequestOptions | undefined
): Promise<List<T>> {
  console.log('CRUD', params);

  return client.get(resolveAppUrl(apiEndpoint, { interpolations: params, params: options })).then(({ data }) => {
    const results = data.data.results ?? data.data;
    const total = data.data.total ?? data.data.length;

    return {
      results,
      total
    };
  });
}

/**
 * Export `useCrudRequestInvalidator`.
 */

export function useCrudRequestInvalidator(key: string[] | undefined) {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries(key ?? ['temp']);
  }, [key, queryClient]);
}

/**
 * Export `useCrudList`.
 */

export function useCrudList<T>(template: CrudTemplate<T>, params: any, options: RequestOptions | undefined) {
  const client = useRequestProvider();

  return useQuery(template.list?.key ?? ['temp'], {
    queryFn: async () => {
      if (!template.list || options === undefined) {
        return [];
      }

      if (!template.list?.endpoint) {
        const data: any[] = template.list?.nestedList?.(params.item) ?? [];

        return {
          results: data,
          total: data?.length
        };
      }

      const result = await getData<T>(client, template.list.endpoint, params, options);

      return (template.list?.normalizeData ?? identity)(result as any);
    }
  });
}
