/**
 * Module dependencies.
 */

import { Navigate, Route, Routes } from 'react-router-dom';
import { Home } from 'app/components/pages/home/home';
import { appRoutes } from 'app/routes';
import React, { useMemo } from 'react';
import { DashboardHeader } from './components/templates/dashboard/dashboard-header';
import { SkinDashboard } from './components/templates/dashboard/skin-dashboard';
import { DashboardOverview } from './components/pages/dashboard/overview/dashboard-overview';
import { SkinSettings } from './components/templates/dashboard/skin-settings';
import { SettingsOverview } from './components/pages/settings/overview/settings-overview';
import { SettingsProfile } from './components/pages/settings/profile/settings-profile';
import { useTranslation } from 'react-i18next';
import { SkinUsers } from './components/templates/dashboard/skin-users';
import { UsersOverview } from './components/pages/users/overview/users-overview';
import { createUsersTemplate } from './components/pages/users/users/form-config';
import { listCrudRoutes } from './components/organisms/crud/utils';
import { UsersDetailOverview } from './components/pages/users/user-detail-overview/user-detail-overview';
import { BiometricData } from './components/pages/users/biometric-data/biometric-data';
import { createUserSettingsTemplate } from './components/pages/users/settings/form-config';
import { createUserAuthorizationsTemplate } from './components/pages/users/authorizations/form-config';
import { createUserPushTokensTemplate } from './components/pages/users/push-tokens/form-config';
import { createUserPinNumbersTemplate } from './components/pages/users/pin-numbers/form-config';
import { MedicalInstrumentsOverview } from './components/pages/medical-instruments/overview/medical-instruments-overview';
import { SkinMedicalInstruments } from './components/templates/dashboard/skin-medical-instruments';
import { createDrugsTemplate } from './components/pages/medical-instruments/drugs/form-config';
import { createLanguagesTemplate } from './components/pages/settings/languages/form-config';
import { SkinTables } from './components/templates/dashboard/skin-tables';
import { TablesOverview } from './components/pages/tables/overview/tables-overview';
import { createDialCodesTemplate } from './components/pages/tables/dial-codes/form-config';
import { createDevicesTemplate } from './components/pages/tables/devices/form-config';
import { createMissingDrugsTemplate } from './components/pages/medical-instruments/missing-drugs/form-config';
import { ConvertMissingDrug } from './components/pages/medical-instruments/missing-drugs/convert-missing-drug';

/**
 * Export `AppRoutes` component.
 */

export function AppRoutes(): JSX.Element {
  const [translate] = useTranslation();
  const usersTemplate = useMemo(() => createUsersTemplate(translate), [translate]);
  const usersSettingsTemplate = useMemo(() => createUserSettingsTemplate(translate), [translate]);
  const usersAuthorizationsTemplate = useMemo(() => createUserAuthorizationsTemplate(translate), [translate]);
  const usersPushTokensTemplate = useMemo(() => createUserPushTokensTemplate(translate), [translate]);
  const usersPinNumbersTemplate = useMemo(() => createUserPinNumbersTemplate(translate), [translate]);

  // Medical instruments
  const drugsTemplate = useMemo(() => createDrugsTemplate(translate), [translate]);
  const missingDrugsTemplate = useMemo(() => createMissingDrugsTemplate(translate), [translate]);

  // Tables
  const dialCodesTemplate = useMemo(() => createDialCodesTemplate(translate), [translate]);
  const devicesTemplate = useMemo(() => createDevicesTemplate(translate), [translate]);

  // Settings
  const languagesTemplate = useMemo(() => createLanguagesTemplate(translate), [translate]);

  return (
    <Routes>
      <Route Component={() => <Home />} path={appRoutes.home} />

      <Route Component={() => <DashboardHeader />} path={appRoutes.dashboard.base}>
        <Route Component={() => <SkinDashboard />} path={appRoutes.dashboard.home.base}>
          <Route Component={() => <DashboardOverview />} index />
        </Route>

        <Route Component={() => <SkinUsers />} path={appRoutes.dashboard.users.base}>
          <Route Component={() => <UsersOverview />} index />
          <Route Component={() => <UsersDetailOverview />} path={appRoutes.dashboard.users.detail.base} />
          <Route Component={() => <BiometricData />} path={appRoutes.dashboard.users.detail.biometricData} />

          {listCrudRoutes(usersTemplate)}
          {listCrudRoutes(usersSettingsTemplate)}
          {listCrudRoutes(usersAuthorizationsTemplate)}
          {listCrudRoutes(usersPushTokensTemplate)}
          {listCrudRoutes(usersPinNumbersTemplate)}
        </Route>

        <Route Component={() => <SkinMedicalInstruments />} path={appRoutes.dashboard.medical.base}>
          <Route Component={() => <MedicalInstrumentsOverview />} index />
          <Route Component={() => <ConvertMissingDrug />} path={appRoutes.dashboard.medical.missingDrugsEdit} />

          {listCrudRoutes(drugsTemplate)}
          {listCrudRoutes(missingDrugsTemplate)}
        </Route>

        <Route Component={() => <SkinTables />} path={appRoutes.dashboard.tables.base}>
          <Route Component={() => <TablesOverview />} index />

          {listCrudRoutes(dialCodesTemplate)}
          {listCrudRoutes(devicesTemplate)}
        </Route>

        <Route Component={() => <SkinSettings />} path={appRoutes.dashboard.settings.base}>
          <Route Component={() => <SettingsOverview />} index />
          <Route Component={() => <SettingsProfile />} path={appRoutes.dashboard.settings.profile} />

          {listCrudRoutes(languagesTemplate)}
        </Route>
      </Route>

      <Route Component={() => <Navigate to={appRoutes.home} />} path={'*'} />
    </Routes>
  );
}
