/**
 * Module dependencies.
 */

import { FormList } from 'app/components/organisms/crud/form-types';
import { requiredRule } from 'app/core/utils/field-rules';
import { TFunction } from 'react-i18next';

/**
 * Left.
 */

const left = (translate: TFunction, method: 'add' | 'edit'): FormList => [
  {
    type: 'inputField',
    name: 'registry',
    disabled: method === 'edit',
    label: translate('common.labels.registry'),
    rules: requiredRule(translate)
  },
  {
    type: 'inputField',
    name: 'name',
    translatable: true,
    label: translate('common.labels.name'),
    rules: requiredRule(translate)
  },
  {
    type: 'inputField',
    inputType: 'textArea',
    translatable: true,
    name: 'therapyInformation',
    label: translate('common.labels.therapyInformation'),
    rules: undefined
  },
  {
    type: 'inputField',
    inputType: 'textArea',
    translatable: true,
    name: 'recommendations',
    label: translate('common.labels.recommendations'),
    rules: undefined
  },
  {
    type: 'timePickerField',
    name: 'predeterminedTime',
    label: translate('common.labels.predeterminedTime'),
    rules: undefined
  },
  {
    type: 'columns',
    name: 'leftWire1',
    columns: [
      {
        column: 8,
        children: [
          {
            type: 'inputNumberField',
            name: 'pillsNumber',
            formatter: value => value,
            label: translate('common.labels.pillsNumber'),
            rules: requiredRule(translate)
          }
        ]
      },
      {
        column: 8,
        children: [
          {
            type: 'inputNumberField',
            name: 'dosage',
            formatter: value => value,
            label: translate('common.labels.dosage'),
            rules: requiredRule(translate)
          }
        ]
      },
      {
        column: 8,
        children: [
          {
            type: 'inputNumberField',
            name: 'maxRecommendedDosage',
            formatter: value => value,
            label: translate('common.labels.maxRecommendedDosage'),
            rules: undefined
          }
        ]
      },
      {
        column: 8,
        children: [
          {
            type: 'inputNumberField',
            name: 'packagingSize',
            formatter: value => value,
            label: translate('common.labels.packagingSize'),
            rules: requiredRule(translate)
          }
        ]
      }
    ]
  },
  {
    type: 'columns',
    name: 'leftWrite2',
    columns: [
      {
        column: 8,
        children: [
          {
            type: 'inputNumberField',
            name: 'activeDays',
            formatter: value => value,
            label: translate('common.labels.activeDays'),
            rules: undefined
          }
        ]
      },
      {
        column: 8,
        children: [
          {
            type: 'inputNumberField',
            name: 'pauseDays',
            formatter: value => value,
            label: translate('common.labels.pauseDays'),
            rules: undefined
          }
        ]
      }
    ]
  },
  {
    type: 'columns',
    name: 'leftWrite3',
    columns: [
      {
        column: 8,
        children: [
          {
            type: 'inputNumberField',
            name: 'therapeuticClassificationCode',
            formatter: value => value,
            label: translate('common.labels.therapeuticClassificationCode'),
            rules: undefined
          }
        ]
      },
      {
        column: 8,
        children: [
          {
            type: 'inputNumberField',
            name: 'cci',
            formatter: value => value,
            label: translate('common.labels.cci'),
            rules: undefined
          }
        ]
      }
    ]
  },
  {
    type: 'columns',
    name: 'leftWrite2',
    columns: [
      {
        column: 8,
        children: [
          {
            type: 'checkBoxField',
            name: 'cronic',
            label: translate('common.labels.cronic'),
            rules: undefined
          }
        ]
      },
      {
        column: 8,
        children: [
          {
            type: 'checkBoxField',
            name: 'active',
            label: translate('common.labels.active'),
            rules: undefined
          }
        ]
      }
    ]
  }
];

/**
 * Right.
 */

const right = (translate: TFunction): FormList => [
  {
    type: 'inputField',
    name: 'id',
    disabled: true,
    label: translate('common.labels.id'),
    rules: undefined
  },
  {
    type: 'inputField',
    name: 'createdAt',
    disabled: true,
    label: translate('common.labels.createdAt'),
    rules: undefined
  },
  {
    type: 'inputField',
    name: 'updatedAt',
    disabled: true,
    label: translate('common.labels.updatedAt'),
    rules: undefined
  }
];

/**
 * Export `formFields`.
 */

export const formFields = (translate: TFunction, method: 'add' | 'edit'): FormList => [
  {
    type: 'columns',
    gutter: 40,
    name: 'mainWire',
    columns: [
      {
        column: 18,
        children: left(translate, method)
      },
      {
        column: 6,
        children: right(translate)
      }
    ]
  }
];
