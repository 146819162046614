/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useMutation } from '@tanstack/react-query';

/**
 * `Payload` type.
 */

type Payload = {
  value: string;
  source: string;
  target: string;
};

/**
 * `Result` type.
 */

type Result = Payload & {
  translatedText: string;
};

/**
 * Post translate.
 */

export function postTranslate(client: AxiosInstance, payload: Payload): Promise<Result> {
  return client.post(resolveAppUrl('translate'), payload).then(({ data }) => data.data.data);
}

/**
 * Export `useTranslate`.
 */

export function useTranslate() {
  const client = useRequestProvider();

  return useMutation({
    mutationFn: async (payload: Payload) => {
      const result = await postTranslate(client, payload);

      return result;
    }
  });
}
