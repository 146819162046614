/**
 * Module dependencies.
 */

import { User } from 'app/types/user';
import React from 'react';
import styles from './user-detail.module.less';
import { Field } from '../field/field';
import { Title } from 'app/components/atoms/title/title';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

/**
 * `Props` type.
 */

type Props = {
  user: User | undefined;
};

/**
 * Export `UserDetail` component.
 */

export function UserDetail({ user }: Props): JSX.Element | null {
  const [translate] = useTranslation();

  if (!user) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Title>{user.name}</Title>

      <div className={styles.content}>
        <div className={styles.column}>
          <Title level={'h2'}>{translate('users.userInfo.userInfo.title')}</Title>

          <Field title={translate('common.labels.id')} value={user.id} />

          <Field title={translate('common.labels.name')} value={user.name} />

          <Field title={translate('common.labels.email')} value={user.email} />

          <Field title={translate('common.labels.phoneNumber')} value={user.phoneNumber} />

          <Field title={translate('common.labels.accessType')} value={user.accessType} />

          <Field title={translate('common.labels.language')} value={user.language} />

          <Field last title={translate('common.labels.gender')} value={user.gender} />
        </div>

        <div className={styles.column}>
          <Title level={'h2'}>{translate('users.userInfo.address.title')}</Title>

          <Field title={translate('common.labels.address')} value={user.address} />

          <Field title={translate('common.labels.postalCode')} value={user.postalCode} />

          <Field title={translate('common.labels.city')} value={user.city} />

          <Field title={translate('common.labels.countryCode')} value={user.countryCode} />

          <Field title={translate('common.labels.nationality')} value={user.nationality} />

          <Field last title={translate('common.labels.birthDate')} value={user.birthDate} />
        </div>

        <div className={styles.column}>
          <Title level={'h2'}>{translate('users.userInfo.status.title')}</Title>

          <Field title={translate('common.labels.confirmed')} value={translate(`common.boolean.${!!user.confirmed}`)} />

          <Field title={translate('common.labels.blocked')} value={translate(`common.boolean.${!!user.blocked}`)} />

          <Field
            title={translate('common.labels.guestAccount')}
            value={translate(`common.boolean.${!!user.guestAccount}`)}
          />

          <Field title={translate('common.labels.uninstall')} value={translate(`common.boolean.${!!user.uninstall}`)} />

          <Field
            title={translate('common.labels.createdAt')}
            value={moment(user.createdAt).format('YYYY-MM-DD HH:mm:ss')}
          />

          <Field
            last
            title={translate('common.labels.updatedAt')}
            value={moment(user.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
          />
        </div>
      </div>
    </div>
  );
}
