/**
 * Module dependencies.
 */

import { useRequestProvider } from 'app/components/providers/request-provider/request-provider';
import { resolveAppUrl } from 'app/core/utils/url-resolver';
import { AxiosInstance } from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { RequestOptions } from 'app/types/network';
import { CrudListType, CrudTemplate } from 'app/components/organisms/crud/form-types';
import { defaultResolveParams } from 'app/core/utils/params';
import { useEffect } from 'react';

/**
 * Resolve endpoint.
 */

export function resolveEndpoint<T>(listTemplate: CrudListType<T>, params: any, options?: RequestOptions): string {
  const resolveParams = listTemplate.resolveParams ?? defaultResolveParams;
  const newParams = resolveParams(params);

  return resolveAppUrl(listTemplate.endpointById as any, { interpolations: newParams, params: options });
}

/**
 * Get crud by id.
 */

function getCrudById<T>(
  client: AxiosInstance,
  listTemplate: CrudListType<T>,
  params: any,
  options?: RequestOptions
): Promise<T> {
  const url = resolveEndpoint(listTemplate, params, options);

  return client.get(url).then(({ data }) => data?.data);
}

/**
 * Export `useCrudById`.
 */

export function useCrudById<T>(
  template: CrudTemplate<T>,
  params: any | undefined,
  options?: RequestOptions,
  queryOptions = {}
) {
  const client = useRequestProvider();
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries([template.list?.key ?? ['temp'], params]);
    };
  }, [params, queryClient, template.list?.key]);

  return useQuery([template.list?.key ?? ['temp'], params], {
    cacheTime: 0,
    staleTime: 0,
    queryFn: async () => {
      if (!params || !template.list?.endpointById) {
        throw new Error('Crud By ID ERROR. No endpoint by id.');
      }

      return await getCrudById<T>(client, template.list, params, options);
    },
    ...queryOptions
  });
}
