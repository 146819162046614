/**
 * Module dependencies.
 */

import { InputField } from 'app/components/atoms/forms/fields/input/input-field';
import React from 'react';
import { InputSearch } from '../form-filters-type';
import { FilterOptions } from './types';

/**
 * Export `FilterInputField` component.
 */

export function FilterInputField(props: FilterOptions<InputSearch>): JSX.Element {
  const { disabled, className, fieldClassName, form, item } = props;
  const { placeholder, label, style, autocomplete } = item;
  const { control } = form;

  return (
    <InputField
      autocomplete={autocomplete}
      className={className}
      control={control}
      disabled={disabled}
      fieldClassName={fieldClassName}
      label={label}
      name={'search'}
      placeholder={placeholder ?? placeholder === false ? undefined : label}
      style={style}
    />
  );
}
